import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, Avatar, Divider } from '@material-ui/core';
import AddResourceSteps from './AddResourceSteps';
import resourceTypes from '../../resourceTypes';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import {Settings} from "../../config/settings";
import {copyResourcePlant, setResourcePlant, validate} from "./serviceFunctions";
import {ResourceUnion} from "../../types";

export type ResourceDetailDialogProps = {
  open: boolean,
  resources: ResourceUnion[];
  resource: any,
  onClose: () => void
  onSave: (resource: any) => Promise<void>
}

const ResourceDetailDialog: React.FC<ResourceDetailDialogProps> = ({ open, onClose, onSave, ...props }) => {
  const [changed, setChanged] = useState(false);
  const [resources, setResources] = useState(props.resources);
  const [resource, setResource] = useState(props.resource);
  const [isValid, setIsValid] = useState(validate(props.resource));
  const [dialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

  useEffect(() => {
    if(!open) return
    // If article code is not set default this to the resource name
    if(props.resource.articleCode===null && props.resource.name)
      props.resource.articleCode=props.resource.name;
    setResource(props.resource);
    setResources(props.resources);
  }, [open, props.resource, props.resources]);

  const handleChanged = useCallback((resource: any) => {
    setResource(resource);
    setIsValid(validate(resource))
    setChanged(true);
  },[]);

  const handlePlantChanged = useCallback((resource: any, plantIndex: number, newPlantIndex: number) => {
    // Copy resource data into related current resource plant data
    resource=setResourcePlant(resource, plantIndex)
    // Copy resource plant data into resource data
    resource=copyResourcePlant(resource, newPlantIndex)

    handleChanged(resource)
  }, [handleChanged]);

  const handleSave = useCallback(async () => {
    // Copy resource plant data of the default plant into resource data
    // because recipe differences will be matched only for the default plant resource properties
    let plantIndex=resource.resource_plants.findIndex(p => p.plant_id===Settings.default_plant_id)
    setResource(copyResourcePlant(resource, plantIndex))
    await onSave(resource);
  },[onSave, resource]);

  const handleClose = useCallback(() => {
    if (changed) {
      confirmClose({
        open: true,
        onCancel: () => confirmClose({ open: false }),
        onConfirm: () => {
          onClose();
          confirmClose({ open: false });
        }
      });
    } else {

      onClose();
    }
  }, [onClose, changed]);

  return (
    <Dialog open={open} maxWidth={'sm'} onClose={handleClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true}>
      <DialogTitle color="primary" style={{marginBottom:'.75rem'}}><div style={{ display: 'flex', alignItems: 'center' }}><Avatar style={{ background: resourceTypes[resource.type as keyof typeof resourceTypes].color, marginRight: 8 }}><img src={resourceTypes[resource.type as keyof typeof resourceTypes].icon} alt="" style={{ width: 24, height: 24 }} /></Avatar><span>Grondstof bewerken</span></div></DialogTitle>
      <Divider />
      <AddResourceSteps resources={resources} resource={resource} onChange={handleChanged} onPlantChange={handlePlantChanged} isValid={isValid} onSave={handleSave} onCancel={handleClose} />
      <ConfirmDialog {...dialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
    </Dialog>
  )
}

export default ResourceDetailDialog;
