import React, {ChangeEvent, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {getAll as getSieveSets} from "../../HTTPClients/RecipeApp/sieve_analysis/sieve-sets";
import {post} from "../../HTTPClients/RecipeApp/sieve_analysis/sieve-tests"
import {
  Button,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Divider,
  DialogActions,
  TableRow,
  DialogTitle,
  Dialog,
  FormGroup,
  FormControlLabel,
  Switch,
  InputAdornment,
  TableFooter,
} from "@material-ui/core";
import {
  SieveSet,
  SieveSetType,
  SieveTest,
  SieveTestStep
} from "../../types";
import {
  getCummulativeRestPercentages,
  getFinenessModulus,
  setCummulativeFallThroughPercentages
} from "../../computed/recipeComputations";
import NumericTextField from "../NumericTextField";
import NumberFormat from "react-number-format";

export type AddSieveTestProps = {
  resourceName: string,
  resourceIsSand: boolean,
  onClose: () => void
  onSaved: (sieveTest: SieveTest, setSelectAfterSave: boolean) => void
}

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

const initialSieveTest: SieveTest = {
  description: '',
  startingWeight: null,
  sieveSet: null,
  sieveSteps: [] as SieveTestStep[],
  finenessModulus: 0
}

const AddSieveTest: React.FC<AddSieveTestProps> = ({resourceName, resourceIsSand, onClose, onSaved}) => {
  const [sieveTest, setSieveTest] = useState(initialSieveTest)
  const [sieveSets, setSieveSets] = useState([] as SieveSet[])
  const [inputMethod, setInputMethod] = useState('weight')
  const [percentages, setPercentages] = useState([])
  const [selectAfterSave, setSelectAfterSave] = useState(true)
  const [isValid, setIsValid] = useState(false);
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(sieveSets.length) return
    getSieveSets().then(
      function(response) {
        let ss=response.data.data
        ss.sort(function (a, b) {
          if(a.code < b.code)
            return -1;
          if(a.code > b.code)
            return 1;
          return 0;
        })
        setSieveSets(ss)
      }
    )
  },[sieveSets, setSieveSets]);

  useEffect(()=>{
    firstInputRef.current && firstInputRef.current.focus()
  },[firstInputRef])

  useEffect(() => {
    validate(sieveTest)
  },[sieveTest])

  const handleInputMethod = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputMethod((e.target as HTMLInputElement).value);
    setCummulativeFallThroughPercentages(sieveTest)
  },[sieveTest, setInputMethod]);

  const handleChangeSieveSet = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const newSieveSet = sieveSets.find((s: SieveSet) => s.id === Number(e.target.value));
    if(newSieveSet) {
      setSieveTest({ ...sieveTest,
        sieveSteps: newSieveSet.sieveSteps.map(({ sieveSize }) => ({ sieveSize, restWeight: 0, fallThrough: 0 } as any)),
        sieveSet: newSieveSet,
        finenessModulus: 0,
        startingWeight: 0
      })
      setPercentages([])
    }
    validate(sieveTest)
  },[sieveSets, setSieveTest, sieveTest]);

  const handleChangeSieveRest = useCallback((sieveStep: SieveTestStep, value?: number) => {
    if(!sieveTest.startingWeight) return
    let index=sieveTest.sieveSteps.findIndex((step: SieveTestStep) => step === sieveStep)
    if(index===-1) return

    if(value===undefined) {
      sieveTest.sieveSteps[index].fallThrough=0
      sieveTest.sieveSteps[index].restWeight=0
    } else {
      sieveTest.sieveSteps[index].restWeight=value as any
    }

    let percentages=getCummulativeRestPercentages(sieveTest)
    setPercentages(percentages)
    setCummulativeFallThroughPercentages(sieveTest)

    setSieveTest({ ...sieveTest, sieveSteps: sieveTest.sieveSteps, finenessModulus: getFinenessModulus(percentages) })
    validate(sieveTest)

  },[sieveTest, setSieveTest, setPercentages])

  const handleChangeSieveFallThrough = useCallback((sieveStep: SieveTestStep, value?: number) => {
    if(!sieveTest.startingWeight) return
    let index=sieveTest.sieveSteps.findIndex((step: SieveTestStep) => step === sieveStep);
    if(index===-1) return

    if(value===undefined) {
      sieveTest.sieveSteps[index].fallThrough=0
      sieveTest.sieveSteps[index].restWeight=0
    } else {
      sieveTest.sieveSteps[index].fallThrough=value

      index=0
      let restWeight=sieveTest.startingWeight
      while(index<sieveTest.sieveSteps.length) {
        // Skip empty sievestep rows
        if(!sieveTest.sieveSteps[index].fallThrough) {
          index++
          continue
        }

        // Determine previous fallthrough percentage
        let fallThroughPrevious=0
        if(index) {
          let indexPrevious=index-1
          while(indexPrevious>-1 && !fallThroughPrevious) {
            fallThroughPrevious=sieveTest.sieveSteps[indexPrevious].fallThrough
            indexPrevious--
          }
        }

        // Calculate and set sieve rest weight
        let cumulativeWeightRetainedPercentage=(100 - sieveTest.sieveSteps[index].fallThrough)
        let weightRetainedPercentage=cumulativeWeightRetainedPercentage
        if(fallThroughPrevious) {
          let previousCumulativeWeightRetainedPercentage=(100 - fallThroughPrevious)
          weightRetainedPercentage=cumulativeWeightRetainedPercentage - previousCumulativeWeightRetainedPercentage
        }
        let sieveRestWeight=Math.round(weightRetainedPercentage * (sieveTest.startingWeight / 100) * 10) / 10
        if(sieveRestWeight<0) sieveRestWeight=0

        sieveTest.sieveSteps[index].restWeight = sieveRestWeight

        restWeight-=sieveRestWeight

        // Next sieve
        index++
      }

      sieveTest.sieveSteps[sieveTest.sieveSteps.length-1].restWeight = restWeight
    }

    let percentages = getCummulativeRestPercentages(sieveTest);
    setPercentages(percentages)
    setSieveTest(sieveTest => ({ ...sieveTest, sieveSteps: sieveTest.sieveSteps, finenessModulus: getFinenessModulus(percentages) }))
    validate(sieveTest)

  },[sieveTest])

  function validate(sieveTest: any) {
    setIsValid(Boolean(sieveTest.startingWeight) &&
      Boolean(sieveTest.sieveSteps.length) &&
      (sieveTest.sieveSteps.filter(ss=>ss.restWeight>0)).length>0
    )
  }

  const handleSave = useCallback(async (sieveTest: any) => {
    post(sieveTest).then(
      function(response) {
        sieveTest=response.data.data
        onSaved(sieveTest, selectAfterSave)
      }
    )
  },[selectAfterSave, onSaved])

  return (
    <Fragment>
    <Dialog open={true} maxWidth={'sm'} onClose={onClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true}>
      <DialogTitle>Zeefanalyse aanmaken</DialogTitle>
      <Divider />
      <div className={'dialog-section'}>
        <Grid container={true} spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Omschrijving"
              value={sieveTest.description || ''}
              onChange={e => setSieveTest({...sieveTest, description: e.target.value })}
              variant="outlined"
              fullWidth={true}
              inputRef={firstInputRef}
              autoFocus={true}
            />
          </Grid>
          <Grid item={true} xs={6}>
            {sieveSets.length>0 && <TextField
              label="Zeefset"
              value={(sieveTest && sieveTest.sieveSet && sieveTest.sieveSet.id) || ''}
              onChange={handleChangeSieveSet as any}
              variant="outlined"
              fullWidth={true}
              required={true}
              select={true}
            >
              <MenuItem>Geen</MenuItem>
              {sieveSets.filter((s: SieveSet) => resourceIsSand ? s.type === SieveSetType.Sand : s.type === SieveSetType.Gravel).map((sieveSet: SieveSet, k: number) => <MenuItem key={k} value={sieveSet.id}>
                {sieveSet.code}
              </MenuItem>)}
            </TextField>}
          </Grid>
          {sieveTest && (<Fragment>
            <Grid item={true} xs={6}>
              <NumericTextField
                label="Startgewicht"
                value={sieveTest.startingWeight || ''}
                onChange={e => {
                  setSieveTest({...sieveTest, startingWeight: e.target.value });
                  validate(sieveTest)
                }}
                fullWidth={true}
                required={true}
                maximumFractionDigits={2}
                InputProps={{
                  endAdornment: <InputAdornment position="end">g</InputAdornment>
                }}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                label="Invoermethode"
                value={inputMethod}
                onChange={handleInputMethod}
                variant="outlined"
                fullWidth={true}
                required={true}
                select={true}
              >
                <MenuItem key={'weight'} value={'weight'}>Zeefrest gewichten</MenuItem>
                <MenuItem key={'percentage'} value={'percentage'}>Doorval percentages</MenuItem>
              </TextField>
            </Grid>
            <Grid item={true} xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: '6rem', padding: '0'}}>Zeefmaat<br />&nbsp;</TableCell>
                    {inputMethod==='percentage' && <TableCell style={{width: '9rem', padding: '0'}} align="right">Doorval (%)<br />cumulatief</TableCell>}
                    <TableCell style={{width: '9rem', padding: '0', textAlign: inputMethod==='percentage' ? 'right' : 'left'}}>Zeefrest (g)<br />&nbsp;</TableCell>
                    <TableCell style={{width: '9rem', padding: '0'}} align="right">Zeefrest (%)<br />cumulatief</TableCell>
                    {inputMethod==='weight' && <TableCell style={{width: '9rem', padding: '0'}} align="right">Doorval (%)<br />&nbsp;</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sieveTest.sieveSteps.sort((a: SieveTestStep, b: SieveTestStep) => b.sieveSize.size - a.sieveSize.size).map((step: SieveTestStep, k: number) =>
                  {
                    let cummulative = percentages && percentages.find(p => p.sieveSize.size === step.sieveSize.size);

                    return (
                      <TableRow key={k}>
                        <TableCell style={{padding: '0'}}>{step.sieveSize.code}</TableCell>
                        {inputMethod==='weight' && <TableCell style={{padding: '0'}}>
                          <NumericTextField
                            placeholder="Zeefrest"
                            value={step.restWeight || ''}
                            onChange={(e: any) => handleChangeSieveRest(step, e.target.value)}
                            margin="dense"
                            fullWidth={true}
                            required={true}
                            maximumFractionDigits={2}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">g</InputAdornment>
                            }}
                          />
                        </TableCell>}
                        {inputMethod==='percentage' && <TableCell style={{padding: '0'}}>
                          <NumericTextField
                            placeholder="Doorval"
                            value={step.fallThrough || ''}
                            onChange={(e: any) => handleChangeSieveFallThrough(step, e.target.value)}
                            margin="dense"
                            fullWidth={true}
                            required={true}
                            maximumFractionDigits={2}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                          />
                        </TableCell>}
                        {inputMethod==='percentage' && <TableCell style={{padding: '0'}} align="right"><Fragment><NumberFormat value={step.restWeight} {...numberFormatProps} />g</Fragment></TableCell>}
                        <TableCell style={{padding: '0'}} align="right">{Boolean(cummulative) && <Fragment><NumberFormat value={cummulative.percentage} {...numberFormatProps} />%</Fragment>}</TableCell>
                        {inputMethod==='weight' && <TableCell style={{padding: '0'}} align="right">{Boolean(cummulative) && cummulative.percentage>0 && <Fragment><NumberFormat value={100-cummulative.percentage} {...numberFormatProps} />%</Fragment>}</TableCell>}
                      </TableRow>
                    )
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>Fijnheidsmodulus</TableCell>
                    <TableCell />
                    <TableCell align="right"><NumberFormat value={sieveTest.finenessModulus} {...numberFormatProps} decimalScale={2} /></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Fragment>)}
        </Grid>
      </div>
      {resourceName!=='' && <div className={'dialog-section next'}>
        <h4 className={!isValid ? 'disabled':''}>Grondstof {resourceName}</h4>
        {<FormGroup>
          <FormControlLabel control={<Switch defaultChecked={true} onChange={e => setSelectAfterSave(e.target.checked)} />}
           disabled={!isValid} label="Pas de nieuwe zeefanalyse op deze grondstof toe" />
        </FormGroup>}
      </div>}
      <Divider />
      <DialogActions style={{ position: 'relative' }}>
        <span style={{ flex: 1 }} />
        <Button onClick={onClose} color="secondary">Annuleren</Button>
        <Button id={'save_sievetest'} onClick={()=>handleSave(sieveTest)} disabled={!isValid} color="primary">Opslaan</Button>
      </DialogActions>
    </Dialog>
    </Fragment>
  )
}

export default AddSieveTest;
