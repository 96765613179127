import React, {useCallback, Fragment} from 'react';
import { Recipe, ResourceType, Ingredient, Addition } from '../../types';
import {Table, TableHead, TableRow, TableCell, TableBody, Card, Link} from '@material-ui/core';
import {
  getVolume,
  getPercentageFine,
  getMoisture,
  getAbsorption,
  getCementTotal,
  getMaxWbf, getPercentageSand
} from '../../computed/recipeComputations';
import { grey } from '@material-ui/core/colors';
import { CardProps } from '@material-ui/core/Card';
import TableEditRow from './TableEditRow';
import { format } from 'react-numberinput-formatter';
import useAuthorized from '../../useAuthorized';
import {calculateAdditions} from "./RecipeDialogContent";

const RecipeSummary: React.FC<{ recipe: Recipe, displayType: 'internal' | 'external', defaultPlant: boolean, goto: (step: number) => void, onChange: (recipe: Recipe) => void } & Omit<CardProps, 'onChange'>> = ({ recipe, displayType, defaultPlant, goto, onChange, ...cardProps }) => {

  let additions = recipe.ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & { resource: Addition }>
  let sandAddition=additions.filter(r => r.resource.isSand);
  if(sandAddition.length)
    calculateAdditions(recipe.ingredients, recipe.airPercentage, getPercentageSand(recipe.ingredients));

  const { ingredients, wbf } = recipe;
  additions = ingredients.filter(r => r.resource.type === ResourceType.Addition).sort((a, b) => (a.resource as Addition).isSand ? -1 : 0);

  const additionTotal = getVolume(additions);
  const cement = ingredients.filter(r => r.resource.type === ResourceType.Cement);

  const fillers = ingredients.filter(r => r.resource.type === ResourceType.Filler);
  const excipients = ingredients.filter(r => r.resource.type === ResourceType.Excipient);
  const extra = ingredients.filter(r => r.resource.type === ResourceType.Extra);
  const water = ingredients.find(r => r.resource.type === ResourceType.Water);
  const los = !Boolean(recipe.strengthClass);
  const canEdit = useAuthorized(['update:recipes']) && defaultPlant
  const handleChange = useCallback((ingredient: Ingredient) => {
    const index = ingredients.findIndex(r => r.resource.id === ingredient.resource.id);
    index >= 0 && (ingredients[index] = { ...ingredient });
    onChange({ ...recipe, ingredients });
  }, [ingredients, recipe, onChange]);

  return (
    <Card {...cardProps}>
      <Table>
        <TableHead>
          <TableRow style={{ background: grey[50] }}>
            <TableCell>Toeslagen{canEdit && <Fragment>&nbsp;&nbsp;<Link onClick={() => goto(los ? 4 : 8)} className="no-print">Aanpassen</Link></Fragment>}</TableCell>
            <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>%</TableCell>
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>Hoeveelheid</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>Fijn materiaal</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>Vocht (incl. absorptie) %</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>Absorptie %</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>Vocht</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>Nat gewicht</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {additions.map((a, k) => <TableEditRow key={k} ingredient={a} additionTotal={additionTotal} displayType={displayType} onChange={handleChange} />)}
        </TableBody>
        <TableHead>
          <TableRow style={{ background: grey[50] }}>
            <TableCell>Cement{canEdit && <Fragment>&nbsp;&nbsp;<Link onClick={() => goto(los ? 2 : 6)} className="no-print">Aanpassen</Link></Fragment>}</TableCell>
            <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>%</TableCell>
            {displayType === 'internal' && <TableCell align="right">Hoeveelheid</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Fijn materiaal</TableCell>}
            {displayType === 'internal' && <TableCell colSpan={4} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {cement.map((c, k) => <TableRow key={k}>
            <TableCell>{c.resource.name}</TableCell>
            <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(c.percentage,{maximumFractionDigits: 1 })} %</TableCell>
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(c.amount, { maximumFractionDigits: 1 })} kg</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(getPercentageFine([c]), { maximumFractionDigits: 1 })} L</TableCell>}
            {displayType === 'internal' && <TableCell colSpan={4} />}
          </TableRow>)}
        </TableBody>
        {fillers.length > 0 && <TableHead>
          <TableRow style={{ background: grey[50] }}>
            <TableCell>Vulstof{canEdit && <Fragment>&nbsp;&nbsp;<Link onClick={() => goto(los ? 2 : 6)} className="no-print">Aanpassen</Link></Fragment>}</TableCell>
            <TableCell align="right">K</TableCell>
            {displayType === 'internal' && <TableCell align="right">Hoeveelheid</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Fijn materiaal</TableCell>}
            {displayType === 'internal' && <TableCell colSpan={4} />}
          </TableRow>
        </TableHead>}
        {fillers.length > 0 && <TableBody>
          {fillers.map((f, k) => {
            let lines = [f];
            if (recipe.attest && f.kFactor && f.attestPercentage) {
              const cementTotal = getCementTotal(ingredients);
              const max = cementTotal * (f.attestPercentage / 100);
              if (f.amount > max) {
                lines = [{ ...f, amount: max, kFactor: 1 }, { ...f, amount: f.amount - max, kFactor: 0 }]
              }
            }
            return (
              <Fragment key={k}>
                {lines.map((line, i) => <TableRow key={i}>
                  {i === 0 && <TableCell rowSpan={lines.length}>{f.resource.name}</TableCell>}
                  <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(line.kFactor || 0, { maximumFractionDigits: 2 })}</TableCell>
                  {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(line.amount, { maximumFractionDigits: 1 })} kg</TableCell>}
                  {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(getPercentageFine([line]), { maximumFractionDigits: 1 })} L</TableCell>}
                  {displayType === 'internal' && <TableCell colSpan={4} />}
                </TableRow>)}
              </Fragment>)
          })}
        </TableBody>}
        {excipients.length > 0 && <TableHead>
          <TableRow style={{ background: grey[50] }}>
            <TableCell colSpan={2}>Hulpstoffen{canEdit && <Fragment>&nbsp;&nbsp;<Link onClick={() => goto(los ? 3 : 7)} className="no-print">Aanpassen</Link></Fragment>}</TableCell>
            {displayType === 'internal' && <TableCell align="right">Hoeveelheid</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Fijn materiaal</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Vocht (incl. absorptie) %</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Absorptie %</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Vocht</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Nat gewicht</TableCell>}
          </TableRow>
        </TableHead>}
        {excipients.length > 0 && <TableBody>
          {excipients.map((e, k) => <TableEditRow key={k} ingredient={e} additionTotal={additionTotal} displayType={displayType} onChange={handleChange} />)}
        </TableBody>}
        {extra.length > 0 && <TableHead>
          <TableRow style={{ background: grey[50] }}>
            <TableCell colSpan={2}>Extra{canEdit && <Fragment>&nbsp;&nbsp;<Link onClick={() => goto(los ? 3 : 7)} className="no-print">Aanpassen</Link></Fragment>}</TableCell>
            {displayType === 'internal' && <TableCell align="right">Hoeveelheid</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Fijn materiaal</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Vocht (incl. absorptie) %</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Absorptie %</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Vocht</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Nat gewicht</TableCell>}
          </TableRow>
        </TableHead>}
        {extra.length > 0 && <TableBody>
          {extra.map((e, k) => <TableEditRow key={k} ingredient={e} additionTotal={additionTotal} displayType={displayType} onChange={handleChange} />)}
        </TableBody>}
        <TableHead>
          <TableRow style={{ background: grey[50] }}>
            <TableCell>Water{canEdit && <Fragment>&nbsp;&nbsp;<Link onClick={() => goto(los ? 2 : 6)} className="no-print">Aanpassen</Link></Fragment>}</TableCell>
            <TableCell align="right">WBF</TableCell>
            {displayType === 'internal' && <TableCell colSpan={2} />}
            {displayType === 'internal' && <TableCell align="right">Vocht (incl. absorptie)</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Absorptie</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Verschil</TableCell>}
            {displayType === 'internal' && <TableCell align="right">Te doseren</TableCell>}
          </TableRow>
        </TableHead>
        {water && <TableBody>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap' }}>Totaal {format(water.amount, { maximumFractionDigits: 1 })} L</TableCell>
            <TableCell align="right" style={{ whiteSpace: 'nowrap' }}><span className={wbf>getMaxWbf(recipe.environmentClasses) ? 'MuiTypography-colorError' : ''}>{format(wbf || 0, { maximumFractionDigits: 3 })}</span></TableCell>
            {displayType === 'internal' && <TableCell colSpan={2} />}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(getMoisture(ingredients), { maximumFractionDigits: 1 })} kg</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(getAbsorption(ingredients), { maximumFractionDigits: 1 })} kg</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(getMoisture(ingredients) - getAbsorption(ingredients), { maximumFractionDigits: 1 })} kg</TableCell>}
            {displayType === 'internal' && <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{format(water.amount - (getMoisture(ingredients) - getAbsorption(ingredients)), { maximumFractionDigits: 1 })} L</TableCell>}
          </TableRow>
        </TableBody>}
      </Table>
    </Card>
  )
}

export default RecipeSummary;
