import React, {ReactText, useCallback, useContext, useEffect, useState} from 'react'
import { Recipe, RecipeStatus } from '../../types'
import useAuthorized from '../../useAuthorized';
import { Icon, TableCell, Box, Button, ButtonGroup, Popper, Grow, Paper, MenuList, MenuItem, ClickAwayListener, Typography } from '@material-ui/core';
import { useHistory, Redirect } from 'react-router-dom';
import {Column, Filter, Sorting} from '@devexpress/dx-react-grid';
import UsageRequirementsIcon from '../../icons/UsageRequirementsIcon';
import DataTable from '../DataTable';
import UserRequirements from '../../icons/UserRequirements';
import { green, grey } from '@material-ui/core/colors';
import { BreadcrumbsContext } from '../../views/PageWithBreadcrumbs'

const RecipeStatusIcon: React.FC<{ status: RecipeStatus }> = ({ status }) => status === RecipeStatus.Error ? <Icon color="error" fontSize="small" style={{ margin: '-4px 0' }}>warning</Icon> : status === RecipeStatus.Published ? <Icon style={{ color: green[600], margin: '-4px 0' }} fontSize="small">cloud_done</Icon> : null

const IDCell: React.FC<{ row: Recipe, onEdit: (recipe: Recipe) => void, onTogglePublish: (recipe: Recipe) => void, onDelete: (recipe: Recipe) => void, onCopy: (recipe: Recipe) => void }> = ({ row, onEdit, onTogglePublish, onDelete, onCopy }) => {
  const canUpdate = useAuthorized(['update:recipes', 'update:recipes_remarks_internal', 'update:recipes_remarks_external'])
  const canCopy = useAuthorized(['create:recipes'])
  const canPublish = useAuthorized(['publish:recipes'])
  const canArchive = useAuthorized(['archive:recipes'])
    return (
    <TableCell style={{ overflow: 'show', position: 'relative' }}>
      {/*@ts-ignore*/}
      <Box display="flex" alignItems="center" height="100%"><span style={{ paddingRight: 8 }}>{row.id}</span><RecipeStatusIcon status={row.status} /></Box>
      {/*@ts-ignore*/}
      <Box position="absolute" top={0} display="flex" bgcolor={grey[100]} alignItems="center" className="row-hover-buttons">
        <span style={{ padding: '12px 8px 12px 0', whiteSpace: 'nowrap' }}>{row.id}</span><RecipeStatusIcon status={row.status} />
        {/*@ts-ignore*/}
        <Box display="flex">
          {canUpdate && <Button onClick={() => onEdit(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">create</Icon>&nbsp;&nbsp;Bewerken</Button>}
          {canCopy && <Button onClick={() => onCopy(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">filter_none</Icon>&nbsp;&nbsp;Kopiëren</Button>}
          {canPublish && row.status === RecipeStatus.Published && <Button onClick={() => onTogglePublish(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">archive</Icon>&nbsp;&nbsp;Depubliceren</Button>}
          {canPublish && row.status === RecipeStatus.Unpublished && <Button onClick={() => onTogglePublish(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">publish</Icon>&nbsp;&nbsp;Publiceren</Button>}
          {canArchive && <Button onClick={() => onDelete(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">delete</Icon>&nbsp;&nbsp;Archiveren</Button>}
        </Box>
      </Box>
    </TableCell>
  )
};

const AddNewComponent: React.FC<{ label: string, items: Array<{ onClick: () => void, icon: JSX.Element, label: string }> }> = ({ label, items }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  return (
    <React.Fragment>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
        <Button onClick={items[0].onClick}><Icon>add_circle</Icon>&nbsp;&nbsp;{label}</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => setOpen(!open)}
        >
          <Icon>keyboard_arrow_down</Icon>
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu">
                  {items.map(({ label, icon, onClick }, k) => <MenuItem key={k} onClick={onClick}>{icon}&nbsp;&nbsp;{label}</MenuItem>)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

const ToolbarSelectionComponent: React.FC<{ selection: Array<number | string | React.ReactText>, onPublish: () => void, onUnpublish: () => void, onDelete: () => void }> = ({ selection, onPublish, onUnpublish, onDelete }) => (
  <React.Fragment>
    <Typography variant="subtitle2">{selection.length + (selection.length === 1 ? ' geselecteerd recept' : ' geselecteerde recepten')}</Typography>&nbsp;&nbsp;
    <Button onClick={onPublish} color="inherit">Publiceren</Button>
    <Button onClick={onUnpublish} color="inherit">Depubliceren</Button>
    <Button onClick={onDelete} color="inherit"><Icon>delete</Icon>&nbsp;&nbsp;Archiveren</Button>
  </React.Fragment>
);

const RecipesList: React.FC<{ recipes: Recipe[], onPublish: (ids: number[]) => void, onUnpublish: (ids: number[]) => void,
  onDelete: (ids: number[]) => void, onCopy: (recipe: Recipe) => void, onTogglePublish: (recipe: Recipe) => void,
  selectionChange: (Array<number | string | ReactText>)}> = ({ recipes, onPublish, onUnpublish, onDelete, onCopy, onTogglePublish, selectionChange }) => {
  const history = useHistory()
  const authorized = useAuthorized(['read:recipes'])
  const canAdd = useAuthorized(['create:recipes'])
  // const canUpdate = useAuthorized(['update:recipes', 'update:recipes_remarks_internal', 'update:recipes_remarks_external'])
  const { createBreadcrumbs } = useContext(BreadcrumbsContext)

  useEffect(() => createBreadcrumbs([]), [createBreadcrumbs])

  const handleAddClick = useCallback((type: 'los' | 'loge') => history.push('/recipe/add/' + type), [history])

  const handleEditClick = useCallback((recipe: Recipe) => history.push('/recipe/' + recipe.id + '/overview'), [history])

  const renderAddNewComponent = useCallback(() => <AddNewComponent label="Nieuw recept" items={[{ label: 'Levering op gebruikseisen', icon: <UsageRequirementsIcon />, onClick: () => handleAddClick('loge') }, { label: 'Levering op samenstelling', icon: <UserRequirements />, onClick: () => handleAddClick('los') }]} />, [handleAddClick]);
  const renderIDCell = useCallback((props: any) => <IDCell {...props} onEdit={handleEditClick} onTogglePublish={onTogglePublish} onDelete={recipe => recipe.id && onDelete([recipe.id])} onCopy={onCopy} />, [handleEditClick, onTogglePublish, onDelete, onCopy])
  const renderToolbarSelectionComponent = useCallback(({ selection }: any) => <ToolbarSelectionComponent onPublish={() => onPublish(selection)} onUnpublish={() => onUnpublish(selection)} onDelete={() => onDelete(selection)} selection={selection} />, [onPublish, onUnpublish, onDelete])

  // Recipe filters
  const [filters]=useState(getFilters())
  function getFilters() {
    let filters=localStorage.getItem('recipe-filters');
    if(filters===null) return [];
    return JSON.parse(filters);
  }
  const filtersChange = useCallback((filters: Filter[]) => {
    localStorage.setItem('recipe-filters', JSON.stringify(filters));
  },[]);

  // Recipe sorting
  const [sorting, setSorting]=useState(getSorting())
  function getSorting() {
    let sorting=localStorage.getItem('recipe-sorting');
    if(sorting===null) return [{ columnName: 'id', direction: 'asc' }];
    return JSON.parse(sorting);
  }
  const sortingChange = useCallback((sorting: Sorting[]) => {
    localStorage.setItem('recipe-sorting', JSON.stringify(sorting));
    setSorting(sorting);
  },[setSorting]);

  const columns: Array<Column & { width?: number, filteringEnabled?: boolean, renderCellComponent?: any }> = [
    { title: 'ID', name: 'id', renderCellComponent: renderIDCell, width: 100 },
    { title: 'Receptnaam', name: 'recipeName' },
    { title: 'Sterkteklasse', width: 130, name: 'strengthClass', getCellValue: (row: Recipe) => row.strengthClass ? row.strengthClass.code : 'LOS' },
    { title: 'Milieuklasse', width: 130, name: 'environmentClasses', getCellValue: (row: Recipe) => row.environmentClasses && row.environmentClasses.length > 0 ? row.environmentClasses.map(e => e.code).join(', ') : 'LOS' },
    { title: 'Consistentieklasse', width: 170, name: 'consistencyClass', getCellValue: (row: Recipe) => row.consistencyClass ? row.consistencyClass.code : 'LOS' },
    { title: 'Opmerkingen', width: 225, name: 'remarksInternal' },
  ];

  return !authorized ?
    // @ts-ignore
    <Redirect to="/unauthorized" /> :
    <DataTable title="Recepten" rows={recipes} sorting={sorting} columns={columns}
      withSelection={true} withSearch={true} withFiltering={true}  withSorting={true}
      AddNewComponent={canAdd ? renderAddNewComponent : undefined}
      ToolbarSelectionComponent={renderToolbarSelectionComponent}
      actions={[]}
      defaultFilters={filters} onFiltersChange={filtersChange}
      onSelectionChange={selectionChange}
      onSortingChange={sortingChange}
    />
}

export default RecipesList
