import React, {Fragment, useRef, useState} from 'react'
import { StepContentComponent } from './AddResourceSteps'
import { TextField, Grid, Typography, MenuItem } from '@material-ui/core'
import { DosingMethod, Excipient } from '../../types'
import useAuthorized from '../../useAuthorized'
import {getAll as getExcipientEffects} from "../../HTTPClients/RecipeApp/resources/excipientEffects";

export const renderExcipientPropertiesOptionalElement = (resource: Excipient) => {
    let excipientEffects=[];
    let mainEffect='';
    let secondaryEffect='';

    getExcipientEffects().then(
        function(response) {
          excipientEffects=response.data.data;
          for(let excipientEffect of excipientEffects) {
            if(excipientEffect.id===resource.mainExcipientEffectId)
              mainEffect=excipientEffect.name;
            if(excipientEffect.id===resource.secondaryExcipientEffectId)
              secondaryEffect=excipientEffect.name;
          }
        }
    )

  return <Fragment>
    {resource.dosingMethod ? <Fragment><Typography component="span" variant="caption">Sterkte 7 dagen: </Typography><Typography component="span" variant="body2">{resource.dosingMethod}</Typography></Fragment> : null}
    {resource.dosingMethod && (resource.mainEffect || resource.secondaryEffect) ? <span>, </span> : null}
    {resource.mainExcipientEffectId ? <Fragment><Typography component="span" variant="caption">Sterkte 28 dagen (norm): </Typography><Typography component="span" variant="body2">{mainEffect}</Typography></Fragment> : null}
    {(resource.dosingMethod || resource.mainEffect) && resource.secondaryEffect ? <span>, </span> : null}
    {resource.secondaryExcipientEffectId ? <Fragment><Typography component="span" variant="caption">Sterkte 28 dagen (norm): </Typography><Typography component="span" variant="body2">{secondaryEffect}</Typography></Fragment> : null}
  </Fragment>
};

const ExcipientPropertiesStep: StepContentComponent<Excipient> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const disabled = !useAuthorized(['update:resources_excipient'])
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [excEffects, setExcEffects] = useState([]);

  React.useEffect(() => {
    getExcipientEffects().then(
        function(response) {setExcEffects(response.data.data)}
    )
  },[]);

/*  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
  }, [stepContentProps.active, resource.id]);*/

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <TextField
          label="Doseerwijze (receptuur)"
          value={resource.dosingMethod || DosingMethod.Percent}
          onChange={e => onChange({ ...resource, dosingMethod: e.target.value as DosingMethod })}
          variant="outlined"
          fullWidth={true}
          required={true}
          select={true}
          disabled={disabled}
          inputRef={firstInputRef}
        >
          <MenuItem>Geen</MenuItem>
          <MenuItem value={DosingMethod.Percent}>%</MenuItem>
          <MenuItem value={DosingMethod.Amount}>kg</MenuItem>
          <MenuItem value={DosingMethod.Liter}>liter</MenuItem>
        </TextField>
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          label="Opmerkingen"
          value={resource.remarks || ''}
          onChange={e => onChange({ ...resource, remarks: e.target.value })}
          variant="outlined"
          fullWidth={true}
          disabled={disabled}
        ></TextField>
      </Grid>
      <Grid item={true} lg={6}>
        <TextField
            label="Primair effect"
            value={resource.mainExcipientEffectId || '0'}
            onChange={e => onChange({ ...resource, mainExcipientEffectId: parseInt(e.target.value) })}
            variant="outlined"
            fullWidth={true}
            required={true}
            select={true}
            disabled={disabled}
        >
          <MenuItem value={0}>Geen</MenuItem>
          {excEffects.map( (excipientEffect) => {
            return <MenuItem key={excipientEffect.id} value={excipientEffect.id}>{excipientEffect.name}</MenuItem>
          })}
        </TextField>
      </Grid>
      <Grid item={true} lg={6}>
        <TextField
          label="Secundair effect"
          value={resource.secondaryExcipientEffectId || '0'}
          onChange={e => onChange({ ...resource, secondaryExcipientEffectId: parseInt(e.target.value) })}
          variant="outlined"
          fullWidth={true}
          select={true}
          disabled={disabled}
        >
          <MenuItem>Geen</MenuItem>
          {excEffects.map( (excipientEffect) => {
            return <MenuItem key={excipientEffect.id} value={excipientEffect.id}>{excipientEffect.name}</MenuItem>
          })}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default ExcipientPropertiesStep;
