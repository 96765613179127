import React, {ChangeEvent, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {
  CardContent,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import {CurrencyFormat, NumberFormat} from '../NumberFormat';
import {Addition, Cement, Excipient, Extra, Filler,Recipe, ResourceType,} from '../../types';
import {
  getAlkaliPercentage,
  getBasePrice,
  getBinderTotal,
  getChloridePercentage,
  getLargestGrain,
  getPercentageFine,
  getPredictedStrength,
  getRecipeConstraints,
  getVolume
} from '../../computed/recipeComputations';
import NumericTextField from '../NumericTextField';
import useAuthorized from '../../useAuthorized';
import {getPlant} from "../../HTTPClients/RecipeApp/resources/resources";

export const PopoverEditableElement: React.FC<{ onSubmit: () => void }> = ({ children, onSubmit }) => {
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if ([13 /* ENTER */, 9 /* TAB */].indexOf(e.keyCode) >= 0) {
      e.preventDefault();
      onSubmit();
    }
  }, [onSubmit]);
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleKeyDown]);
  return (
    <CardContent>
      {children}
    </CardContent>
  )
}

type EditableElementProps = {
  anchorEl: Element | ((element: Element) => Element) | null | undefined
  onSubmit: () => void
  renderPopoverContent: () => JSX.Element
}

const EditableElement: React.FC<EditableElementProps> = ({ anchorEl, onSubmit, renderPopoverContent }) => {
  const [altAnchorEl, setAltAnchorEl] = useState(null as null | Element);
  const handleSubmit = useCallback(() => {
    setAltAnchorEl(null);
    onSubmit();
  }, [onSubmit]);
  return <React.Fragment>
    <IconButton style={{ margin: '-12px -12px -12px 0' }} onClick={e => setAltAnchorEl(e.currentTarget)}><Icon fontSize="small">create</Icon></IconButton>
    <Popover
      open={Boolean(altAnchorEl)}
      anchorEl={anchorEl || altAnchorEl}
      onClose={() => { setAltAnchorEl(null); handleSubmit(); }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <PopoverEditableElement onSubmit={handleSubmit}>
        {renderPopoverContent()}
      </PopoverEditableElement>
    </Popover>
  </React.Fragment>
}

type SummaryPropertyNumberProps = {
  value: number,
  unit?: (React.ReactText | JSX.Element),
  isCurrency?: boolean,
  maximumFractionDigits?: number
}

const SummaryPropertyNumber: React.FC<SummaryPropertyNumberProps> = ({ value, unit, isCurrency, maximumFractionDigits }) => {
  return isCurrency ? <CurrencyFormat value={value} /> : <Fragment><NumberFormat value={value} maximumFractionDigits={maximumFractionDigits} />{unit ? <Fragment> {unit}</Fragment> : null}</Fragment>
}

type SummaryListItemProps = SummaryPropertyNumberProps & {
  label: string
  min?: number
  max?: number
  editable?: boolean
  onChange?: (value: number) => void
}

const SummaryListItem: React.FC<SummaryListItemProps> = ({ label, value, isCurrency, unit, min, max, maximumFractionDigits, editable, onChange }) => {
  const [editValue, setEditValue] = useState(value as number | undefined);
  const ref = useRef(null)
  const error = (min && min > value) || (max && max < value);
  const canEdit = useAuthorized(['update:recipes'])
  useEffect(() => setEditValue(value), [value]);
  return (
    <ListItem innerRef={ref}>
      <ListItemText
        primary={label}
        secondary={min ? <Fragment>Minimaal: <SummaryPropertyNumber value={min} unit={unit} isCurrency={isCurrency} maximumFractionDigits={maximumFractionDigits} /></Fragment> : max ? <Fragment>Maximaal: <SummaryPropertyNumber value={max} unit={unit} isCurrency={isCurrency} maximumFractionDigits={maximumFractionDigits} /></Fragment> : null}
      />
      <ListItemSecondaryAction style={{ display: 'flex', alignItems: 'center' }}>
        {error && <Fragment><Icon color="error" fontSize="small">error</Icon>&nbsp;</Fragment>}
        <Typography display="inline" color={error ? 'error' : 'inherit'} variant="body2">
          <SummaryPropertyNumber value={value} unit={unit} isCurrency={isCurrency} maximumFractionDigits={maximumFractionDigits} />
        </Typography>
        {canEdit && editable && <EditableElement
          anchorEl={ref.current}
          onSubmit={() => onChange && editValue && onChange(editValue)}
          renderPopoverContent={() => (
            <NumericTextField
              label="Luchtpercentage"
              value={editValue}
              onChange={e => setEditValue(e.target.value)}
            />
          )}
        />}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const Summary: React.FC<{ recipe: Recipe, onChange: (recipe: Recipe) => void, plants: Array<any>, plantId: number, onPlantChange: (plantId: number) => void}> = ({ recipe, onChange, plants, plantId, onPlantChange }) => {
  const { binderTotal, percentageFine, predictedStrength, chloridePercentage, alkaliPercentage, airPercentage } = getRecipeConstraints(recipe);
  const [plant, setPlant] = useState(plantId)

  useEffect(()=> {
    setPlant(plantId)
  },[plantId])

  const plantChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);
    setPlant(value);

    getPlant(value).then(
      function(response) {
        let resources=response.data.data;

        // Set plant related resource properties for this recipe
        for(let ingredient of recipe.ingredients) {
          let index=resources.findIndex(r => r.id===ingredient.resource.id);
          if(index<0)
            continue;

          ingredient.resource.price=resources[index].price;
          ingredient.resource.density=resources[index].density;
          ingredient.resource.alkaliPercentage=resources[index].alkaliPercentage;
          ingredient.resource.chloridePercentage=resources[index].chloridePercentage;
          ingredient.resource.cvalue=resources[index].cvalue;
          ingredient.resource.brand=resources[index].brand;
          ingredient.resource.supplier=resources[index].supplier;
          if(ingredient.resource.type===ResourceType.Addition) {
            (ingredient.resource as Addition).absorption=resources[index].absorption;
            (ingredient.resource as Addition).moisture=resources[index].moisture;
            (ingredient.resource as Addition).sieveTest=resources[index].sieveTest;
            (ingredient.resource as Addition).gradingCurve=resources[index].gradingCurve
          }
          if(ingredient.resource.type===ResourceType.Cement) {
            (ingredient.resource as Cement).strengthWeek=resources[index].strengthWeek;
            (ingredient.resource as Cement).strengthNorm=resources[index].strengthNorm
          }
          if(ingredient.resource.type===ResourceType.Filler) {
            (ingredient.resource as Filler).cementKFactors=resources[index].cementKFactors
          }
          if(ingredient.resource.type===ResourceType.Extra) {
            (ingredient.resource as Extra).absorption=resources[index].absorption;
            (ingredient.resource as Extra).moisture=resources[index].moisture;
            (ingredient.resource as Extra).percentageFine=resources[index].percentageFine
          }
          if(ingredient.resource.type===ResourceType.Excipient) {
            (ingredient.resource as Excipient).absorption=resources[index].absorption;
            (ingredient.resource as Excipient).moisture=resources[index].moisture;
            (ingredient.resource as Excipient).dosingMethod=resources[index].dosingMethod;
            (ingredient.resource as Excipient).mainEffect=resources[index].mainEffect;
            (ingredient.resource as Excipient).mainExcipientEffectId=resources[index].mainExcipientEffectId;
            (ingredient.resource as Excipient).secondaryEffect=resources[index].secondaryEffect;
            (ingredient.resource as Excipient).secondaryExcipientEffectId=resources[index].secondaryExcipientEffectId
          }
        }

        onPlantChange(value);
        onChange(recipe);
      }
    )
  },[recipe, onChange, onPlantChange]);

  return (
    <Fragment>
      <CardContent>
        <Typography variant="subtitle2">Eigenschappen specie</Typography>
      </CardContent>
      {plantId>0 && <TextField
        label="Vestiging"
        value={plant}
        onChange={plantChange}
        variant="filled"
        className={'plantSelector'}
        size={'small'}
        fullWidth={true}
        select={true}
        disabled={plants.length<2}
      >
        {plants.map( (plant) => {
          return <MenuItem key={plant.id} value={plant.id}>{plant.name}</MenuItem>
        })}
      </TextField>}
      <List dense={true}>
        <SummaryListItem
          label="Volumieke massa"
          value={recipe.ingredients.reduce((sum, r) => sum += r.amount, 0)}
          unit={<Fragment>kg/m<sup>3</sup></Fragment>}
          maximumFractionDigits={2}
        />
        <SummaryListItem
          label="Uitlevering volume"
          value={getVolume(recipe.ingredients) + recipe.airPercentage * 10}
          unit={<Fragment>m<sup>3</sup></Fragment>}
          maximumFractionDigits={2}
        />
        <SummaryListItem
          label="Totaal bindmiddel"
          value={getBinderTotal(recipe.ingredients, Boolean(recipe.attest))}
          unit="kg"
          min={binderTotal.min}
          maximumFractionDigits={1}
        />
        <SummaryListItem
          label="Fijnmateriaal"
          value={getPercentageFine(recipe.ingredients, recipe)}
          unit="liter"
          maximumFractionDigits={2}
          min={percentageFine.min}
        />
        <SummaryListItem
          label="Berekende sterkte"
          value={getPredictedStrength(recipe.ingredients, recipe.wbf || 0)}
          unit={<Fragment>N/mm<sup>2</sup></Fragment>}
          maximumFractionDigits={1}
          min={predictedStrength.min}
        />
        <SummaryListItem
          label="Chloridegehalte"
          value={getChloridePercentage(recipe.ingredients)}
          unit="%"
          maximumFractionDigits={4}
          max={chloridePercentage.max}
        />
        <SummaryListItem
          label="Alkaligehalte"
          value={getAlkaliPercentage(recipe.ingredients)}
          unit="%"
          max={alkaliPercentage.max}
          maximumFractionDigits={4}
        />
        <SummaryListItem
          label="DMax"
          value={getLargestGrain(recipe.ingredients.filter(r => r.resource.type === ResourceType.Addition) as any) || 0}
        />
        <SummaryListItem
          label="Luchtgehalte"
          value={recipe.airPercentage}
          unit="%"
          max={airPercentage.max}
          editable={true}
          onChange={airPercentage => onChange({ ...recipe, airPercentage })}
        />
        <SummaryListItem
          label="Basisprijs"
          value={getBasePrice(recipe.ingredients)}
          isCurrency={true}
        />
      </List>
    </Fragment>
  )
}

export default Summary;
