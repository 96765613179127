import axiosHttpClient from "../axiosHttpClient";

export async function get(resource_id: number) {
  return axiosHttpClient.get('/resource-plants/'+resource_id.toString());
}

export async function getOne(plant_id: number, resource_id: number) {
  return axiosHttpClient.get('/resource-plants/one/'+plant_id.toString()+'/'+resource_id.toString());
}

export async function getGradingCurvesHistory(plant_id: number, resource_id: number) {
  return axiosHttpClient.get('/resource-plants/grading-curves-history/'+plant_id.toString()+'/'+resource_id.toString());
}
