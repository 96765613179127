import {ResourceType, SieveSetType} from "../../types";

export function initPlantResources(plants: any[], resource: any) {
  resource.plants=plants
  resource.resource_plants=[]
  for(let plant of plants)
    resource.resource_plants.push(emptyPlantResource(plant.id))
  return resource
}

export function emptyPlantResource(plant_id: number) {
  return { plant_id: plant_id, brand: null, supplier: null, price: null, absorption: null, moisture: null, isSand: null,
    density: null, chloridePercentage: null, alkaliPercentage: null, percentageFine: null, cvalue: null, remarks: null,
    dosingMethod: null, mainEffect: null, mainExcipientEffectId: null, secondaryEffect: null, secondaryExcipientEffectId: null,
    gradingCurve: null, sieveTest: null, strengthWeek: null, strengthNorm: null, cementKFactors: []
  }
}

// Copy resource plant data into resource data
export function copyResourcePlant(resource: any, plantIndex: number) {
  resource.brand=resource.resource_plants[plantIndex].brand
  resource.supplier=resource.resource_plants[plantIndex].supplier
  resource.price=resource.resource_plants[plantIndex].price
  resource.absorption=resource.resource_plants[plantIndex].absorption
  resource.moisture=resource.resource_plants[plantIndex].moisture
  resource.density=resource.resource_plants[plantIndex].density
  resource.chloridePercentage=resource.resource_plants[plantIndex].chloridePercentage
  resource.alkaliPercentage=resource.resource_plants[plantIndex].alkaliPercentage
  resource.percentageFine=resource.resource_plants[plantIndex].percentageFine
  resource.cvalue=resource.resource_plants[plantIndex].cvalue
  resource.gradingCurve=resource.resource_plants[plantIndex].gradingCurve
  resource.sieveTest=resource.resource_plants[plantIndex].sieveTest
  resource.strengthWeek=resource.resource_plants[plantIndex].strengthWeek
  resource.strengthNorm=resource.resource_plants[plantIndex].strengthNorm
  resource.cementKFactors=resource.resource_plants[plantIndex].cementKFactors

  return resource
}

// Set resource data into related current resource plant data
export function setResourcePlant(resource: any, plantIndex: number) {
  resource.resource_plants[plantIndex].brand=resource.brand
  resource.resource_plants[plantIndex].supplier=resource.supplier
  resource.resource_plants[plantIndex].price=resource.price
  resource.resource_plants[plantIndex].absorption=resource.absorption
  resource.resource_plants[plantIndex].moisture=resource.moisture
  resource.resource_plants[plantIndex].density=resource.density
  resource.resource_plants[plantIndex].chloridePercentage=resource.chloridePercentage
  resource.resource_plants[plantIndex].alkaliPercentage=resource.alkaliPercentage
  resource.resource_plants[plantIndex].percentageFine=resource.percentageFine
  resource.resource_plants[plantIndex].cvalue=resource.cvalue
  resource.resource_plants[plantIndex].gradingCurve=resource.gradingCurve
  resource.resource_plants[plantIndex].sieveTest=resource.sieveTest
  resource.resource_plants[plantIndex].strengthWeek=resource.strengthWeek
  resource.resource_plants[plantIndex].strengthNorm=resource.strengthNorm
  resource.resource_plants[plantIndex].cementKFactors=resource.cementKFactors

  return resource
}

// Input validations
export function validate(resource: any) {
  // Validate common resource data
  if(!Boolean(resource.name)) return false
  if(!Boolean(resource.articleCode)) return false

  // Validate plant related resource data in current edited tabset tab
  if(!Boolean(resource.price)) return false
  if(!Boolean(resource.density)) return false
  if(resource.type===ResourceType.Excipient && !(resource.mainExcipientEffectId>0)) return false
  if(resource.type===ResourceType.Cement && !Boolean(resource.strengthNorm)) return false
  if(resource.type===ResourceType.Filler && !Boolean(resource.cementKFactors)) return false
  if(resource.type===ResourceType.Addition) {
    if(!Boolean(resource.sieveTest)) return false
    if(!Boolean(resource.sieveTest.startingWeight)) return false
    if(!resource.sieveTest.sieveSteps) return false
  }

  // Validate all plant related resource data
  for(let plant of resource.plants) {
    let plantIndex=resource.resource_plants.findIndex(p => p.plant_id===plant.id)
    let resource_plant=resource.resource_plants[plantIndex]

    if(!Boolean(resource_plant.price)) return false
    if(!Boolean(resource_plant.density)) return false
    if(resource.type===ResourceType.Cement && !Boolean(resource_plant.strengthNorm)) return false
    if(resource.type===ResourceType.Filler && !Boolean(resource_plant.cementKFactors)) return false
    if(resource.type===ResourceType.Addition) {
      if(!Boolean(resource_plant.sieveTest)) return false
      if(!Boolean(resource_plant.sieveTest.startingWeight)) return false
      if(!resource_plant.sieveTest.sieveSteps) return false
    }
  }

  return true
}

export function sieveSetTypeAsString(type: SieveSetType) {
  switch(type) {
    case SieveSetType.Sand: return 'Zand'
    case SieveSetType.Gravel: return 'Grind'
    case SieveSetType.Mixture: return 'Mengsel'
  }
  return '';
}
