import React, { Fragment, useState, useCallback } from 'react'
import {
  TextField,
  Chip,
  Box,
  MenuItem,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Snackbar
} from '@material-ui/core'
import { SieveSize, GradingCurve, SieveSet } from '../../types'
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog'
import AddSettingDialog from './AddSettingDialog'
import SettingsTable, { Column } from './SettingsTable'
import SettingsComponent from './SettingsComponent'
import {useHistory} from 'react-router-dom'
import {EditTableCell, PopoverEditField} from '../recipes/TableEditRow'
import {getAll, getOne, deleteAllowed, post, remove} from "../../HTTPClients/RecipeApp/grading-curves";
import {getAll as getSieveSets} from '../../HTTPClients/RecipeApp/sieve_analysis/sieve-sets';
import CustomSnackbarContent from "../CustomSnackbarContent";

type SieveStep = { sieveSize: SieveSize, min: number, max: number, maxAlt?: number }

const types = { sand: 'Zand', gravel: 'Grind', mixture: 'Mengsel' }

const GradingCurves: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [sieveSets, setSieveSets]=useState([]);
  if(!sieveSets.length) {
    getSieveSets().then(
        function(response) {
          setSieveSets(response.data.data)
        }
    )
  }

  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)
  const [filter, setFilter] = useState('')
  const history = useHistory()
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const columns: Column[] = [
    { name: 'code', title: 'Code', style: { whiteSpace: 'nowrap' } },
    { name: 'description', title: 'Beschrijving' },
    { name: 'type', title: 'Type', getValue: (v: keyof typeof types) => types[v] },
    {
      name: 'sieveSteps', title: 'Zeefstappen', getValue: (steps: SieveStep[]) => {
        return <Box maxWidth={420}>
          {steps.sort((a, b) => b.sieveSize.size - a.sieveSize.size).map(({ sieveSize }, k) => <Chip
            key={k}
            label={sieveSize.code}
            size="small"
            clickable={true}
            onClick={() => history.push('/settings/sieve_sizes/edit/' + sieveSize.id)}
            variant="outlined"
            style={{ marginTop: 2, marginBottom: 2, marginRight: 4 }}
          />)}
        </Box>
      }
    }
  ]

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  },[])

  const getRow = useCallback(async (gradingCurveId: string) => {
    let response=await getOne(Number(gradingCurveId));
    return response.data.data;
  },[])

  const handleSave = useCallback(async (gradingCurve: GradingCurve, gradingCurves: GradingCurve[] | undefined, saveCallback: (gradingCurves?: GradingCurve[]) => void) => {
    if (gradingCurves && gradingCurve.id) {
      post(gradingCurve).then(
          function(response) {
            const index = gradingCurves.findIndex(f => f.id === gradingCurve.id)
            index >= 0 && (gradingCurves[index] = gradingCurve)
            saveCallback(gradingCurves);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (gradingCurves) {
      post(gradingCurve).then(
          function(response) {
            gradingCurves.push({ ...gradingCurve, id: response.data.data.id })
            saveCallback(gradingCurves);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze zeeflijn bestaat al'
    })
  }

  const handleDelete = useCallback((gradingCurve: GradingCurve, deleteCallback: (gradingCurve: GradingCurve) => void) => {
    deleteAllowed(gradingCurve.id).then(
        function(response) {
          if(response.data.data) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(gradingCurve.id).then(
                    function(response) {
                      deleteCallback(gradingCurve);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze zeeflijn mag niet verwijderd worden'
    })
  }

  const handleSieveStepChange = useCallback((key: 'min' | 'maxAlt' | 'max', value: number, sieveStep: SieveStep, row: GradingCurve) => {
    if (row.sieveSteps) {
      const index = row.sieveSteps.findIndex(s => s.sieveSize.size === sieveStep.sieveSize.size)
      if (index >= 0) {
        row.sieveSteps[index][key] = value
      }
    }
    return row.sieveSteps
  }, [])

  const SearchElement = <TextField placeholder="Zeeflijn zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/grading_curves"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0)}
          onEdit={(gradingCurve: GradingCurve) => gradingCurve.id && onEdit(gradingCurve.id)}
          onDelete={(gradingCurve: GradingCurve) => handleDelete(gradingCurve, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Zeeflijn bewerken' : 'Zeeflijn toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
            disableDialogContent={true}
          >
            <Stepper orientation="vertical">
              <Step active={true}>
                <StepLabel>Zeeflijn informatie</StepLabel>
                <StepContent>
                  <TextField
                    label="Zeeflijn code"
                    value={row.code || ''}
                    onChange={e => onChange({ ...row, code: e.target.value })}
                    variant="outlined"
                    margin="normal"
                    fullWidth={true}
                    required={true}
                  />
                  <TextField
                    label="Beschrijving"
                    value={row.description || ''}
                    onChange={e => onChange({ ...row, description: e.target.value })}
                    variant="outlined"
                    margin="normal"
                    fullWidth={true}
                    multiline={true}
                  />
                  <TextField
                    label="Type"
                    value={row.type || ''}
                    onChange={e => onChange({ ...row, type: e.target.value })}
                    variant="outlined"
                    margin="normal"
                    fullWidth={true}
                    select={true}
                    required={true}
                  >
                    {Object.keys(types).map(type => <MenuItem key={type} value={type}>{types[type as keyof typeof types]}</MenuItem>)}
                  </TextField>
                </StepContent>
              </Step>
              <Step active={true}>
                <StepLabel>Gradering gegevens</StepLabel>
                <StepContent>
                  {!row.id && <TextField
                    label="Zeefset"
                    placeholder="Kies een zeefset"
                    variant="outlined"
                    margin="normal"
                    onChange={e => onChange({ ...row, sieveSteps: (e.target.value as unknown as SieveSet).sieveSteps.map(({ sieveSize }) => ({ sieveSize })) })}
                    fullWidth={true}
                    select={true}
                    required={true}
                  >
                    {(sieveSets.length ? sieveSets : []).filter((sieveSet: SieveSet) => !row.type || sieveSet.type === row.type).map((sieveSet: SieveSet, k: number) => <MenuItem key={k} value={sieveSet as any}>{sieveSet.code} ({types[sieveSet.type]})</MenuItem>)}
                  </TextField>}
                  {row.sieveSteps && <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Zeefmaat</TableCell>
                        <TableCell>A (min. zeefrest %)</TableCell>
                        {row.type === 'mixture' && <TableCell>B (max. zeefrest %)</TableCell>}
                        <TableCell>C (max. zeefrest %)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.sieveSteps.sort((a: SieveStep, b: SieveStep) => b.sieveSize.size - a.sieveSize.size).map((sieveStep: SieveStep, k: number) => <TableRow key={k}>
                        <TableCell>{sieveStep.sieveSize.code}</TableCell>
                        <EditTableCell
                          align="right"
                          style={{ whiteSpace: 'nowrap' }}
                          renderPopoverContent={(onClose) => <PopoverEditField
                            label="Zeefrest (%)"
                            value={sieveStep.min || ''}
                            onChange={e => onChange({ ...row, sieveSteps: handleSieveStepChange('min', e.target.value, sieveStep, row) })}
                            onClose={onClose}
                          />}
                        >
                          {sieveStep.min}
                        </EditTableCell>
                        {row.type === 'mixture' && <EditTableCell
                          align="right"
                          style={{ whiteSpace: 'nowrap' }}
                          renderPopoverContent={(onClose) => <PopoverEditField
                            label="Zeefrest (%)"
                            value={sieveStep.maxAlt || ''}
                            onChange={e => onChange({ ...row, sieveSteps: handleSieveStepChange('maxAlt', e.target.value, sieveStep, row) })}
                            onClose={onClose}
                          />}
                        >
                          {sieveStep.maxAlt}
                        </EditTableCell>}
                        <EditTableCell
                          align="right"
                          style={{ whiteSpace: 'nowrap' }}
                          renderPopoverContent={(onClose) => <PopoverEditField
                            label="Zeefrest (%)"
                            value={sieveStep.max || ''}
                            onChange={e => onChange({ ...row, sieveSteps: handleSieveStepChange('max', e.target.value, sieveStep, row) })}
                            onClose={onClose}
                          />}
                        >
                          {sieveStep.max}
                        </EditTableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table>}
                </StepContent>
              </Step>
            </Stepper>
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Zeeflijn verwijderen" content="Weet u zeker dat u deze zeeflijn wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default GradingCurves
