import {Settings} from "../../config/settings";
const axios = require('axios').default;

const axiosClient = axios.create({
  baseURL: Settings.URLs.planningApp.recipeApp.root,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    authentication: localStorage.getItem('token'),
    Company: Settings.company
  }
});

export default axiosClient;
