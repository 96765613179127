import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { Grid, Typography, List, ListItem, ListItemText, Paper, Divider, Icon, ListItemIcon, ListItemSecondaryAction, IconButton, makeStyles } from '@material-ui/core';
import SearchField from '../components/SearchField';
import { grey, amber } from '@material-ui/core/colors';
import { ListItemProps } from '@material-ui/core/ListItem';
import parse from 'autosuggest-highlight/parse';
import Families from '../components/settings/Families';
import SieveSizes from '../components/settings/SieveSizes';
import EnvironmentClasses from '../components/settings/EnvironmentClasses';
import StrengthClasses from '../components/settings/StrengthClasses';
import ChlorideClasses from '../components/settings/ChlorideClasses';
import TypesOfWork from '../components/settings/TypesOfWork';
import SieveSets from '../components/settings/SieveSets';
import GradingCurves from '../components/settings/GradingCurves';
import ExcipientEffectsList from "../components/settings/ExcipientEffectsList";

type Path = {
  title: string
  subTitle?: string
  path?: string
  action?: JSX.Element
  SettingsComponent?: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }>
  sections?: Array<Section | Path>
}

type Section = Omit<Path, 'sections'> & {
  sections: Path[]
}

// @ts-ignore
const settings: Section[] = [
  {
    title: 'Belangrijke documenten',
    sections: [
      {
        path: '/settings/attest',
        title: 'Beheer attesten'
      }
    ]
  },
  {
    title: 'Korrelgrootteverdeling',
    sections: [
      {
        path: '/settings/sieve_sizes',
        title: 'Beheer zeefmaten',
        subTitle: 'Zeefopening maten voor de toeslagmaterialen (bijv. 63mu, C31,5)',
        SettingsComponent: SieveSizes
      },
      {
        path: '/settings/sieve_sets',
        title: 'Beheer zeefsets',
        subTitle: 'Serie van zeefmaten bijelkaar gegroepeerd',
        SettingsComponent: SieveSets
      },
      {
        path: '/settings/grading_curves',
        title: 'Beheer zeeflijn grenzen',
        subTitle: 'Bepaal de grenzen met behulp van min. -max. zeefrest percentage',
        SettingsComponent: GradingCurves
      }
    ]
  },
/*  {
    title: 'Productie',
    sections: [
      {
        path: '/settings/plants',
        title: 'Beheer betoncentrales',
        SettingsComponent: Plants
      }
    ]
  },*/
  {
    title: 'Receptuur',
    sections: [
/*      {
        path: '/settings/consistency',
        title: 'Consistentie instellingen',
        sections: [
          {
            path: '/settings/consistency/classes',
            title: 'Beheer consistentieklassen'
          },
          {
            path: '/settings/consistency/tests',
            title: 'Beheer consistentietesten'
          }
        ]
      },*/
      {
        path: '/settings/environment_classes',
        title: 'Beheer milieuklassen',
        SettingsComponent: EnvironmentClasses
      },
      {
        path: '/settings/strength_classes',
        title: 'Beheer sterkteklassen',
        SettingsComponent: StrengthClasses
      },
      {
        path: '/settings/chloride_classes',
        title: 'Beheer chlorideklassen',
        SettingsComponent: ChlorideClasses
      },
      {
        path: '/settings/types_of_work',
        title: 'Beheer soorten werk',
        SettingsComponent: TypesOfWork
      },
      {
        path: '/settings/families',
        title: 'Beheer familiegroepen',
        SettingsComponent: Families
      },
      {
        path: '/settings/excipient_effects',
        title: 'Beheer hulpstof werkingen',
        SettingsComponent: ExcipientEffectsList
      },
    ]
  }
];

const useStyles = makeStyles({
  tooltip: {
    position: 'absolute',
    minWidth: 100,
    textAlign: 'center',
    zIndex: 1000,
    bottom: -20,
    left: 16,
    background: amber[200],
    padding: '4px 12px',
    fontWeight: 500,
    '&:before': {
      top: -16,
      marginLeft: 4,
      width: 16,
      border: '8px solid transparent',
      height: 0,
      content: '\'\'',
      position: 'absolute',
      borderBottomColor: amber[200]
    }
  }
})

const SettingsListItem: React.FC<(Path | Section) & ListItemProps & { query?: string }> = ({ title, subTitle, action, SettingsComponent, sections, path, query, ...listItemProps }) => {
  const matches = match(title, query || '', true);
  // @ts-ignore
  const parts = parse(title, matches);
  const showTooltip = Boolean(query && matches.length === 0 && sections);
  const classes = useStyles();

  return (
    <Fragment>
      <ListItem {...listItemProps as any} style={{ backgroundColor: 'transparent' }}>
        <ListItemText
          primary={parts.map((part, k) => (
            <span key={k} style={{ fontWeight: part.highlight ? 500 : 400, ...part.highlight ? { background: amber[200] } : {} }}>
              {part.text}
            </span>
          ))}
          secondary={subTitle}
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'body2' }}
          style={{ position: 'relative', overflow: 'show' }}
        />
        {action ? action : <Icon fontSize="small" style={{ transform: 'rotate(-90deg)' }}>arrow_drop_down</Icon>}
        {showTooltip && <Typography className={classes.tooltip} variant="body2">{query}</Typography>}
      </ListItem>
    </Fragment>
  )
}

type SettingsListProps = {
  sections?: Array<Section | Path>
  onClick: (path: string) => void
  parent?: Path
  showPrevious?: boolean
  title?: string
  SettingsComponent?: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }>
  children: (path: (Path | Section) & ListItemProps) => React.FC | JSX.Element
}

const PrevComponent: React.FC<{ title?: string, parent?: Path, onClick: (path: string) => void, SearchElement?: JSX.Element }> = ({ title, parent, onClick, SearchElement }) => {
  return (
    <ListItem>
      <ListItemIcon><IconButton onClick={() => onClick(parent ? parent.path || '' : '/settings')}><Icon fontSize="small">arrow_back</Icon></IconButton></ListItemIcon>
      <ListItemText primary={title} primaryTypographyProps={{ variant: 'subtitle2' }} />
      {SearchElement ? <ListItemSecondaryAction style={{ marginTop: 4, display: 'flex', alignItems: 'center' }}>{SearchElement}</ListItemSecondaryAction> : null}
    </ListItem>
  )
}

const SettingsList: React.FC<SettingsListProps> = ({ sections, onClick, parent, showPrevious, title, SettingsComponent, children }) => {
  const settingsComponent = SettingsComponent ? (PrevComponent: React.FC<{ SearchElement?: JSX.Element }>) => <SettingsComponent PrevComponent={PrevComponent} /> : undefined;
  return (
    <List disablePadding={true}>
      {showPrevious && (settingsComponent ? settingsComponent(({ SearchElement }) => <PrevComponent SearchElement={SearchElement} parent={parent} title={title} onClick={onClick} />) : <PrevComponent parent={parent} title={title} onClick={onClick} />)}
      {sections && sections.map(({ path, action, ...section }, i) => {
        let listItemProps = path ? { button: true, onClick: () => onClick(path || '') } : {};
        return (
          <Fragment key={i}>
            <>
            {i > 0 && <Divider />}
            {children({ path, action, ...section, ...listItemProps })}
            </>
          </Fragment>
        );
      })}
    </List>
  );
}

let fromUrl = false;
let searchTimeout: NodeJS.Timeout;

function matchSection(section: Section | Path, searchValue: string) {
  let found = false;
  section.title.toLowerCase().indexOf(searchValue) >= 0 && (found = true);
  section.subTitle && section.subTitle.toLocaleLowerCase().indexOf(searchValue) >= 0 && (found = true);
  section.sections && section.sections.findIndex(s => matchSection(s, searchValue)) >= 0 && (found = true);
  return found;
}

const Settings: React.FC<RouteComponentProps<{ searchValue: string }>> = ({ history, match, location }) => {
  const [searchValue, setSearchValue] = useState(location.search ? location.search.replace('?search=', '') : '');
  const paths = flattenPaths(settings);

  useEffect(() => {
    if (location.search && !fromUrl) {
      const value = decodeURIComponent(location.search.replace('?search=', ''));
      searchValue !== value && setSearchValue(value);
      fromUrl = true;
    }
  }, [location.search, searchValue])

  const sectionsFilter = useCallback((section: Section) => {
    if (location.search) {
      const value = location.search.replace('?search=', '');
      return matchSection(section, value.toLocaleLowerCase());
    }
    return true;
  }, [location.search]);

  const handleClick = useCallback((path: string) => {
    history.push(path + (location.search ? location.search : ''));
  }, [history, location.search]);

  const handleSearch = useCallback((value: string) => {
    setSearchValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    if (value.length < 4) {
      searchTimeout = setTimeout(() => {
        history.push(match.path + '?search=' + value);
      }, 500);
    } else {
      history.push(match.path + '?search=' + value);
    }
  }, [history, match.path]);

  // @ts-ignore
  // @ts-ignore
  return (
    <Grid container={true} style={{ height: '100%', width: '100%', overflow: 'auto', background: grey[100] }} justifyContent="center">
      <Grid item={true} lg={6} md={8} sm={12} xs={12} style={{ paddingTop: 24, paddingBottom: 24 }}>
        <SearchField
          value={searchValue}
          placeholder="Zoek in de instellingen"
          onChange={handleSearch}
        />
        {/* @ts-ignore */}
        <Switch>
          {paths.reverse().map(({ path, title, sections, SettingsComponent }, k) => {
            const parent = path ? findParent(paths, path) : undefined;
            return (
              /* @ts-ignore */
              <Route key={k} path={path}>
                <Paper style={{ marginTop: 16 }}>
                  <SettingsList sections={sections} onClick={handleClick} parent={parent} showPrevious={true} title={title} SettingsComponent={SettingsComponent}>
                    {({ path, action, ...rest }) => <SettingsListItem path={path} action={action} query={searchValue} {...rest} />}
                  </SettingsList>
                </Paper>
              </Route>
            )
          })}
          {/* @ts-ignore */}
          <Route path="/settings">
            {settings.filter(sectionsFilter).map(({ title, sections }, k) => (
              <div key={k} style={{ marginTop: 32 }}>
                <Typography variant="body2" style={{ marginBottom: 16 }}>{title}</Typography>
                <Paper>
                  <SettingsList sections={sections} onClick={handleClick}>
                    {({ path, action, ...rest }) => <SettingsListItem path={path} action={action} query={searchValue} {...rest} />}
                  </SettingsList>
                </Paper>
              </div>
            ))}
          </Route>
        </Switch>
      </Grid>
    </Grid>
  )
}

export default Settings;

function flattenPaths(sections: Array<Section | Path>): Path[] {
  return sections.reduce((arr, { sections, ...s }) => [...arr, ...s.path ? [{ ...s as Path, sections }] : [], ...sections ? flattenPaths(sections) : []], [] as Path[]);
}

function findParent(paths: Path[], path: string) {
  return paths.find(p => p.sections && p.sections.map(s => s.path).indexOf(path) >= 0);
}

function match(str: string, searchStr: string, caseSensitive: boolean) {
  const searchStrLen = searchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0, index = 0, indices: number[][] = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push([index, index + searchStr.length]);
    startIndex = index + searchStrLen;
  }
  return indices;
}
