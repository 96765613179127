import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
  return axiosHttpClient.post('/sieve-tests/create', data);
}

export function getHistory(resource_id: number) {
	return axiosHttpClient.get('/sieve-tests/history/'+resource_id.toString());
}

