import React, { Fragment, useState, useCallback } from 'react';
import {TextField, Grid, InputAdornment, Snackbar} from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import AddSettingDialog from './AddSettingDialog';
import SettingsTable, { Column } from './SettingsTable';
import SettingsComponent from './SettingsComponent';
import NumberFormat from 'react-number-format';
import NumericTextField from '../NumericTextField';
import {deleteAllowed, getAll, getOne, post, remove} from "../../HTTPClients/RecipeApp/types-of-work";
import CustomSnackbarContent from "../CustomSnackbarContent";
import {
  getOne as getRecipe,
  getWithWorktype,
  post as postRecipe
} from "../../HTTPClients/RecipeApp/recipes/recipes";
import {Recipe, RecipeStatus} from "../../types";
import {getErrors} from "../../computed/recipeComputations";
import {merge} from "lodash";
import {sanitizeRecipe} from "../../sanitize";
import {diff} from "deep-object-diff";
import RecipeChangesDialog, {RecipeChangesDialogProps} from "./types-of-work/RecipeChangesDialog";

const columns: Column[] = [
  {
    name: 'code',
    title: 'Naam',
    getValue: (value: string) => <span style={{ whiteSpace: 'nowrap' }}>{value}</span>
  },
  {
    name: 'description',
    title: 'Beschrijving'
  },
  {
    name: 'maxChloride',
    title: 'Max. chloride gehalte',
    getValue: (value: number) => <NumberFormat displayType="text" value={value} thousandSeparator="." decimalSeparator="," />
  },
  {
    name: 'minPercentageFine',
    title: 'Min. gehalte fijn',
    getValue: (value: number) => <NumberFormat displayType="text" value={value} thousandSeparator="." decimalSeparator="," />
  }
];

const TypesOfWork: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [filter, setFilter] = useState('');
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);
  const [open] = useState(false);
  const [recipeChangesDialogProps, openRecipeChangesDialog] = useState({ open } as RecipeChangesDialogProps)
  const [confirmClosedialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  },[]);

  const getRow = useCallback(async (typeOfWorkId: string) => {
    let response=await getOne(Number(typeOfWorkId));
    return response.data.data;
  },[]);

  const handleSave = useCallback(async (typeOfWork: any, typeOfWorks: any[] | undefined, saveCallback: (typeOfWorks?: any[]) => void) => {
    if (typeOfWorks && typeOfWork.id) {
      const index = typeOfWorks.findIndex(f => f.id === typeOfWork.id);
      if(typeOfWork.minPercentageFine===undefined)
        typeOfWork.minPercentageFine=0;
      index >= 0 && (typeOfWorks[index] = typeOfWork);

      const response=await getWithWorktype(typeOfWork.id);
      const recipes = response.data.data;
      let showRecipeChanges = false
      const diffs: Array<{ diff: any, oldValues: any, recipe: Recipe, errors: string[], oldStatus: RecipeStatus }> = []
      recipes.forEach(r => {
        const { diff, oldValues, recipe } = getDiff(r, typeOfWork)
        const isDiff = Object.keys(diff).length > 0
        !showRecipeChanges && isDiff && (showRecipeChanges = true)
        const keys = Object.keys(diff)
        const errors = getErrors(recipe).filter(e => keys.indexOf(e) >= 0)
        isDiff && diffs.push({ diff, oldValues, recipe: { ...recipe, status: errors.length > 0 ? RecipeStatus.Error : RecipeStatus.Unpublished }, errors, oldStatus: recipe.status })
      })
      if (showRecipeChanges && typeOfWorks) {
        const proceed = await new Promise(async resolve => {
          openRecipeChangesDialog({
            open: true,
            diffs,
            onPrevious: () => {
              openRecipeChangesDialog({ open: false })
              resolve(false)
            },
            onCancel: () => {
              confirmClose({
                open: true,
                onCancel: () => confirmClose({ open: false }),
                onConfirm: () => {
                  openRecipeChangesDialog({ open: false })
                  confirmClose({ open: false })
                }
              })
            },
            onSave: async (recipes: Recipe[]) => {
              const remaining = Array(recipes.length).fill(0)
              while (recipes.length > 0) {
                const promisses: Array<Promise<any>> = []
                recipes.splice(0, 10).forEach(recipe => {
                  promisses.push(new Promise<void>(async resolve => {
                    const result=await getRecipe(recipe.id);

                    const { ingredients, ...rest } = result.data.data as Recipe
                    ingredients.forEach(ingredient => merge(ingredient, recipe.ingredients.find(i => i.resource.id === ingredient.resource.id)))
                    const data = merge(rest, recipe, { ingredients })
                    try {
                      let newData=sanitizeRecipe(data);
                      newData['id']=recipe.id;

                      await postRecipe(newData);
                      remaining.splice(0, 1)
                    } catch (e) {
                      console.log(e);
                    }
                    resolve()
                  }))
                })
                console.log('remaining', remaining.length)
                await Promise.all(promisses)
              }
              resolve(true)
            }
          })
        })
        if (!proceed) return
      }
      post(typeOfWork).then(
        function(response) {
          savedSnackbar();

          const index = typeOfWorks.findIndex(f => f.id === typeOfWork.id);
          if(typeOfWork.minPercentageFine===undefined)
            typeOfWork.minPercentageFine=0;
          index >= 0 && (typeOfWorks[index] = typeOfWork);

          saveCallback(typeOfWorks);
        }
      ).catch(
        function (error) {
          if(error.response===undefined)
            console.log(error);
          else
          if(error.response.data==='not_unique') notUniqueSnackbar();
        }
      ).finally(
        openRecipeChangesDialog({ open: false, resetSaving: true })
      )
    } else if (typeOfWorks) {
      post(typeOfWork).then(
          function(response) {
            typeOfWorks.push({ ...typeOfWork, id: response.data.data.id });
            savedSnackbar();
            saveCallback(typeOfWorks);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze werksoort bestaat al'
    })
  }

  function savedSnackbar() {
    setSnackbar({
      variant: 'success',
      message: 'De werksoort is succesvol opgeslagen'
    })
  }

  const handleDelete = useCallback((typeOfWork: any, deleteCallback: (typeOfWork: any) => void) => {
    deleteAllowed(typeOfWork.id).then(
        function(response) {
          if(response.data.allowed) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(typeOfWork.id).then(
                    function(response) {
                      deleteCallback(typeOfWork);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze werksoort mag niet verwijderd worden'
    })
  }

  const SearchElement = <TextField placeholder="Soort werk zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />;

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/types_of_work"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={columns}
          rows={rows.filter(f => f.code.toLowerCase().indexOf(filter.toLowerCase()) >= 0).sort((a, b) => b.maxChloride - a.maxChloride)}
          onEdit={(typeOfWork: any) => typeOfWork.id && onEdit(typeOfWork.id)}
          onDelete={(typeOfWork: any) => handleDelete(typeOfWork, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Soort werk bewerken' : 'Soort werk toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <Grid container={true} wrap="nowrap">
              <TextField
                label="Naam"
                value={row.code}
                onChange={e => onChange({ ...row, code: e.target.value })}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                style={{ marginRight: 8 }}
              />
              <TextField
                label="Beschrijving"
                value={row.description}
                onChange={e => onChange({ ...row, description: e.target.value })}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                style={{ marginLeft: 8 }}
              />
            </Grid>
            <NumericTextField
              margin="normal"
              label="Max. chloride gehalte"
              value={row.maxChloride}
              onChange={e => onChange({ ...row, maxChloride: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={0}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
            <NumericTextField
              margin="normal"
              label="Min. gehalte fijn"
              value={row.minPercentageFine}
              onChange={e => onChange({ ...row, minPercentageFine: e.target.value })}
              fullWidth={true}
              maximumFractionDigits={0}
              InputProps={{
                endAdornment: <InputAdornment position="end">Liters</InputAdornment>
              }}
            />
          </AddSettingDialog>
        )}
        <RecipeChangesDialog {...recipeChangesDialogProps} />
        <ConfirmDialog {...confirmClosedialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
        <ConfirmDialog {...dialogProps} title="Soort werk verwijderen" content="Weet u zeker dat u dit soort werk wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default TypesOfWork;

function getDiff(recipe: Recipe, typeOfWork: any) {
  // Neglegt code and description fields diffs
  recipe.typeOfWork.code=typeOfWork.code
  recipe.typeOfWork.description=typeOfWork.description

  let oldValues = recipe.typeOfWork;
  let newValues = typeOfWork;
  let newRecipe = { ...recipe };
  newRecipe.typeOfWork=newValues;

  return { diff: diff(oldValues, newValues), oldValues, recipe: newRecipe }
}
