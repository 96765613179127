import React, {ChangeEvent, Fragment, useCallback, useEffect, useState} from 'react';
import {StepContentComponent} from './AddResourceSteps';
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import {Addition, GradingCurve, SieveSet, SieveSetType, SieveTest, SieveTestStep} from '../../types';
import {
  getCummulativeRestPercentages,
  getFinenessModulus, setCummulativeFallThroughPercentages
} from '../../computed/recipeComputations';
import NumberFormat from 'react-number-format';
import GradingCurveChart from '../recipes/GradingCurveChart';
import NumericTextField from '../NumericTextField';
import {getAll as getSieveSets} from "../../HTTPClients/RecipeApp/sieve_analysis/sieve-sets";
import {getAll as getGradingCurves} from "../../HTTPClients/RecipeApp/grading-curves";
import {Alert} from "@material-ui/lab";
import SieveTestsHistory from "./SieveTestsHistory";
import AddSieveTest from "./AddSieveTest";

const numberFormatProps = {
  displayType: 'text' as 'text',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1
}

const SieveTestStepComponent: StepContentComponent<Addition> = ({ onChange, onSave, plantId, resource, ...stepContentProps }) => {
  const [sieveSets, setSieveSets] = useState([] as SieveSet[])
  const [gradingCurves, setGradingCurves] = useState([] as GradingCurve[])
  const [inputMethod, setInputMethod] = useState('weight');
  const percentages = resource.sieveTest && getCummulativeRestPercentages(resource.sieveTest);
  const [finenessModulus, setFinenessModulus] = useState(resource && resource.sieveTest ? getFinenessModulus(percentages) : undefined);
  const [openHistory, setOpenHistory] = useState(false)
  const [openSieveTest, setOpenSieveTest] = useState(false)
  const editResource: boolean=resource.id!==undefined

  useEffect(() => {
    if(sieveSets.length) return
    getSieveSets().then(
      function(response) {
        let ss=response.data.data
        ss.sort(function (a, b) {
          if(a.code < b.code)
            return -1;
          if(a.code > b.code)
            return 1;
          return 0;
        })
        setSieveSets(ss)
      }
    )
  },[sieveSets]);

  useEffect(() => {
    if(gradingCurves.length) return
    getGradingCurves().then(
      function(response) {
        setGradingCurves(sortGradingCurves(response.data.data))
      }
    )
  },[gradingCurves]);

  useEffect(() => {
    if(!Boolean(resource.sieveTest) || !Boolean(resource.sieveTest.startingWeight)) return
    for(let sieveStep of resource.sieveTest.sieveSteps)
      if(sieveStep.restWeight && sieveStep.fallThrough===undefined)
        sieveStep.fallThrough=((resource.sieveTest.startingWeight - sieveStep.restWeight) / resource.sieveTest.startingWeight) * 100
  },[resource.sieveTest])

  function sortGradingCurves(gc: GradingCurve[]) {
    gc.sort(function (a, b) {
      if(a.code.toUpperCase() < b.code.toLowerCase())
        return -1;
      if(a.code.toUpperCase() > b.code.toLowerCase())
        return 1;
      return 0;
    })
    return gc
  }

  const handleInputMethod = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputMethod((e.target as HTMLInputElement).value);
    setCummulativeFallThroughPercentages(resource.sieveTest)
  },[resource, setInputMethod]);

  const handleChangeSieveSet = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const sieveSet = sieveSets.find((s: SieveSet) => s.id === Number(e.target.value));
    if (sieveSet) {
      const sieveTest: SieveTest = {
        startingWeight: 0,
        sieveSteps: sieveSet.sieveSteps.map(({ sieveSize }) => ({ sieveSize, restWeight: 0, fallThrough: 0 } as any)),
        sieveSet: sieveSet,
        finenessModulus: 0,
      } as any
      onChange({ ...resource, sieveTest });
    }
  }, [onChange, resource, sieveSets]);

  const handleChangeSieveRest = useCallback((sieveStep: SieveTestStep, value?: number) => {
    if(!Boolean(resource.sieveTest) || !Boolean(resource.sieveTest.startingWeight)) return
    const index=resource.sieveTest.sieveSteps.findIndex((step: SieveTestStep) => step === sieveStep)
    if(index===-1) return

    if(value===undefined) {
      resource.sieveTest.sieveSteps[index].restWeight=0
      resource.sieveTest.sieveSteps[index].fallThrough=0
    } else {
      resource.sieveTest.sieveSteps[index].restWeight=value as any;
    }

    const percentages=getCummulativeRestPercentages(resource.sieveTest);
    setCummulativeFallThroughPercentages(resource.sieveTest)
    const finenessModulus = getFinenessModulus(percentages);
    setFinenessModulus(finenessModulus);
    onChange({ ...resource, sieveTest: { ...resource.sieveTest, finenessModulus } });

  },[onChange, resource])

  const handleChangeSieveFallThrough = useCallback((sieveStep: SieveTestStep, value?: number) => {
    if(!Boolean(resource.sieveTest) || !Boolean(resource.sieveTest.startingWeight)) return
    let index=resource.sieveTest.sieveSteps.findIndex((step: SieveTestStep) => step === sieveStep)
    if(index===-1) return

    if(value===undefined) {
      resource.sieveTest.sieveSteps[index].fallThrough=0
      resource.sieveTest.sieveSteps[index].restWeight=0
    } else {
      resource.sieveTest.sieveSteps[index].fallThrough=value

      index=0
      let restWeight=resource.sieveTest.startingWeight
      while(index<resource.sieveTest.sieveSteps.length) {
        // Skip empty sievestep rows
        if(!resource.sieveTest.sieveSteps[index].fallThrough) {
          index++
          continue
        }

        // Determine previous fallthrough percentage
        let fallThroughPrevious=0
        if(index) {
          let indexPrevious=index-1
          while(indexPrevious>-1 && !fallThroughPrevious) {
            fallThroughPrevious=resource.sieveTest.sieveSteps[indexPrevious].fallThrough
            indexPrevious--
          }
        }

        // Calculate and set sieve rest weight
        let cumulativeWeightRetainedPercentage=(100 - resource.sieveTest.sieveSteps[index].fallThrough)
        let weightRetainedPercentage=cumulativeWeightRetainedPercentage
        if(fallThroughPrevious) {
          let previousCumulativeWeightRetainedPercentage=(100 - fallThroughPrevious)
          weightRetainedPercentage=cumulativeWeightRetainedPercentage - previousCumulativeWeightRetainedPercentage
        }
        let sieveRestWeight=Math.round(weightRetainedPercentage * (resource.sieveTest.startingWeight / 100) * 10) / 10
        if(sieveRestWeight<0) sieveRestWeight=0

        resource.sieveTest.sieveSteps[index].restWeight = sieveRestWeight

        restWeight-=sieveRestWeight

        // Next sieve
        index++
      }

      resource.sieveTest.sieveSteps[resource.sieveTest.sieveSteps.length-1].restWeight = restWeight
    }
    const percentages = getCummulativeRestPercentages(resource.sieveTest);
    const finenessModulus = getFinenessModulus(percentages);
    setFinenessModulus(finenessModulus);
    onChange({ ...resource, sieveTest: { ...resource.sieveTest, finenessModulus } });

  }, [onChange, resource])

  const handleHistoryOpen = useCallback(async () => {
    setOpenHistory(true)
  },[setOpenHistory])

  const onSieveTestSelect = useCallback((sieveTest: SieveTest) => {
    setOpenHistory(false)
    onChange({...resource, sieveTest: sieveTest })
  },[setOpenHistory, resource, onChange])

  const handleHistoryClose = useCallback(() => {
    setOpenHistory(false)
  },[setOpenHistory])

  const handleGradingCurveOpen = useCallback(async () => {
    setOpenSieveTest(true)
  },[setOpenSieveTest])

  const onSieveTestSaved = useCallback((sieveTest: SieveTest, setSelectAfterSave: boolean) => {
    if(setSelectAfterSave) {
      resource.sieveTest=sieveTest
      onChange(resource)
    }
    setOpenSieveTest(false)
  },[resource, onChange, setOpenSieveTest])

  const handleSieveTestClose = useCallback(async () => {
    setOpenSieveTest(false)
  },[setOpenSieveTest])

  // @ts-ignore
  return ( <Fragment>
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={6}>
        {sieveSets.length && <TextField
          label="Zeefset"
          value={(resource.sieveTest && resource.sieveTest.sieveSet && resource.sieveTest.sieveSet.id) || ''}
          onChange={handleChangeSieveSet as any}
          variant="outlined"
          fullWidth={true}
          required={true}
          select={true}
        >
          <MenuItem>Geen</MenuItem>
          {sieveSets.filter((s: SieveSet) => resource.isSand ? s.type === SieveSetType.Sand : s.type === SieveSetType.Gravel).map((sieveSet: SieveSet, k: number) => <MenuItem key={k} value={sieveSet.id}>
            {sieveSet.code}
          </MenuItem>)}
        </TextField>}
      </Grid>
      {resource.sieveTest && (<Fragment>
        <Grid item={true} xs={6}>
          <NumericTextField
            label="Startgewicht"
            value={resource.sieveTest.startingWeight}
            onChange={(e: any) => onChange({ ...resource, sieveTest: { ...resource.sieveTest as SieveTest, startingWeight: e.target.value } })}
            fullWidth={true}
            required={true}
            maximumFractionDigits={2}
            InputProps={{
              endAdornment: <InputAdornment position="end">g</InputAdornment>
            }}
          />
        </Grid>
        <Grid item={true} xs={12}>
          {resource.id>0 && gradingCurves.length>0 && <div>
            <Button id={'history'} onClick={handleHistoryOpen} color="secondary" tabIndex={-1}>Zeefanalyses history</Button>
            <Button id={'gradingcurve'} onClick={handleGradingCurveOpen} color="secondary" tabIndex={-1}>Zeefanalyse aanmaken</Button>
          </div>}
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            label="Invoermethode"
            value={inputMethod}
            onChange={handleInputMethod}
            variant="outlined"
            fullWidth={true}
            required={true}
            select={true}
            tabIndex={-1}
          >
            <MenuItem key={'weight'} value={'weight'}>Zeefrest gewichten</MenuItem>
            <MenuItem key={'percentage'} value={'percentage'}>Doorval percentages</MenuItem>
          </TextField>
        </Grid>
        <Grid item={true} xs={12}>
          {!resource.sieveTest.sieveSteps.length && <Alert severity="warning">Vul svp tenminste &egrave;&egrave;n zeefrest regel in</Alert>}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{width: '6rem', padding: '0'}}>Zeefmaat<br />&nbsp;</TableCell>
                {inputMethod==='percentage' && <TableCell style={{width: '9rem', padding: '0'}} align="right">Doorval (%)<br />cumulatief</TableCell>}
                <TableCell style={{width: '9rem', padding: '0', textAlign: inputMethod==='percentage' ? 'right' : 'left'}}>Zeefrest (g)<br />&nbsp;</TableCell>
                <TableCell style={{width: '9rem', padding: '0'}} align="right">Zeefrest (%)<br />cumulatief</TableCell>
                {inputMethod==='weight' && <TableCell style={{width: '9rem', padding: '0'}} align="right">Doorval (%)<br />&nbsp;</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {resource.sieveTest.sieveSteps.sort((a: SieveTestStep, b: SieveTestStep) => b.sieveSize.size - a.sieveSize.size).map((step: SieveTestStep, k: number) =>
                {
                  const cummulative = percentages && percentages.find(p => p.sieveSize.size === step.sieveSize.size);

                return (
                  <TableRow key={k}>
                    <TableCell style={{padding: '0'}}>{step.sieveSize.code}</TableCell>
                    {inputMethod==='weight' && <TableCell style={{padding: '0'}}>
                      <NumericTextField
                        placeholder="Zeefrest"
                        value={step.restWeight}
                        onChange={(e: any) => handleChangeSieveRest(step, e.target.value)}
                        margin="dense"
                        fullWidth={true}
                        required={true}
                        maximumFractionDigits={2}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">g</InputAdornment>
                        }}
                      />
                    </TableCell>}
                    {inputMethod==='percentage' && <TableCell style={{padding: '0'}}>
                      <NumericTextField
                        placeholder="Doorval"
                        value={step.fallThrough}
                        onChange={(e: any) => handleChangeSieveFallThrough(step, e.target.value)}
                        margin="dense"
                        fullWidth={true}
                        required={true}
                        maximumFractionDigits={2}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                      />
                    </TableCell>}
                    {inputMethod==='percentage' && <TableCell style={{padding: '0'}} align="right"><Fragment><NumberFormat value={step.restWeight} {...numberFormatProps} />g</Fragment></TableCell>}
                    <TableCell style={{padding: '0'}} align="right"><Fragment><NumberFormat value={cummulative.percentage} {...numberFormatProps} />%</Fragment></TableCell>
                    {inputMethod==='weight' && <TableCell style={{padding: '0'}} align="right">{Boolean(cummulative) && cummulative.percentage>0 && <Fragment><NumberFormat value={100-cummulative.percentage} {...numberFormatProps} />%</Fragment>}</TableCell>}
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Fijnheidsmodulus</TableCell>
                <TableCell />
                <TableCell align="right"><NumberFormat value={finenessModulus} {...numberFormatProps} decimalScale={2} /></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
        {percentages && <GradingCurveChart restPercentages={percentages} gradingCurve={resource.gradingCurve || undefined} width={510} height={300} />}
      </Fragment>)}
      <Grid item={true} xs={12}>
        {gradingCurves.length>0 && <TextField
          label="Zeeflijn"
          value={(resource.gradingCurve && resource.gradingCurve.id) || ''}
          onChange={e => onChange({ ...resource, gradingCurve: gradingCurves.find(gc => gc.id === Number(e.target.value)) as GradingCurve })}
          variant="outlined"
          fullWidth={true}
          select={true}
        >
          <MenuItem>Geen</MenuItem>
          {gradingCurves.filter(s => resource.isSand ? s.type === SieveSetType.Sand : s.type === SieveSetType.Gravel).map((gradingCurve, k) => <MenuItem key={k} value={gradingCurve.id}>
            {gradingCurve.description}
          </MenuItem>)}
        </TextField>}
      </Grid>
    </Grid>
    {editResource && openHistory && <SieveTestsHistory resource={resource} onSelect={onSieveTestSelect} onClose={handleHistoryClose}></SieveTestsHistory>}
    {openSieveTest && <AddSieveTest resourceIsSand={resource.isSand} resourceName={resource.name} onClose={handleSieveTestClose} onSaved={onSieveTestSaved}></AddSieveTest>}
    </Fragment>
  )
}

export default SieveTestStepComponent;
