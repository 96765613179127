import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, DialogActions, Button, Avatar } from '@material-ui/core';
import { ResourceUnion, ResourceType } from '../../types';
import resourceTypes from '../../resourceTypes';
import { grey } from '@material-ui/core/colors';
import ChoseResourceType from './ChoseResourceType';
import AddResourceSteps from './AddResourceSteps';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import {Settings} from "../../config/settings";
import {copyResourcePlant, initPlantResources, setResourcePlant, validate} from "./serviceFunctions";
import {getAll} from "../../HTTPClients/RecipeApp/plants";

const initialResource: any = {
  type: null,
  name: null,
  brand: null,
  supplier: null,
  price: null,
  absorption: null,
  moisture: null,
  density: null,
  isSand: null,
  remarks: null,
  chloridePercentage: null,
  alkaliPercentage: null,
  percentageFine: null,
  cvalue: null,
  dosingMethod: null,
  mainEffect: null,
  mainExcipientEffectId: null,
  secondaryEffect: null,
  secondaryExcipientEffectId: null,
  gradingCurve: null,
  sieveTest: null,
  strengthWeek: null,
  strengthNorm: null,
  cementKFactors: [],
  plants: [],
  resource_plants: []
} as unknown as any

type AddResourceDialogProps = {
  open: boolean,
  resource?: any,
  resources: ResourceUnion[];
  resourceType?: ResourceType,
  onClose: () => void,
  onSave: (resource: any) => Promise<void>
} & RouteComponentProps

const AddResourceDialog: React.FC<AddResourceDialogProps> = ({ open, onClose, onSave, resourceType, history, ...props }) => {
  const [changed, setChanged] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [resources, setResources] = useState(props.resources);
  const [plants, setPlants] = useState([] as any[])
  const [resource, setResource] = useState(props.resource || { ...initialResource, type: resourceType } as any);
  const [dialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);

  useEffect(() => {
    if(changed) return
    if(open && resourceType) {
      setResources(props.resources);
      setResource({ ...initialResource, type: resourceType } as any);
    }
  },[open, props, resourceType, resource]);

  useEffect(() => {
    if(plants.length) return
    getAll().then(
      function(response) {
        let plants=response.data.data
        setPlants(plants)
      }
    )
  },[plants, setPlants]);

  useEffect(() => {
    if(!plants.length) return
    setResource(initPlantResources(plants, resource))
  },[plants, resource]);

  const handleSelectResourceType = useCallback((resourceType: ResourceType) => {
    history.push('/resource/add/' + resourceType.toLowerCase());
  }, [history]);

  const handleChanged = useCallback((resource: any) => {
    setResource(resource);
    setIsValid(validate(resource))
    setChanged(true);
  }, []);

  const handlePlantChanged = useCallback((resource: any, plantIndex: number, newPlantIndex: number) => {
    // Copy resource data into related current resource plant data
    resource=setResourcePlant(resource, plantIndex)

    // Copy resource plant data into resource data
    resource=copyResourcePlant(resource, newPlantIndex)

    handleChanged(resource)
  }, [handleChanged]);

  const handleSave = useCallback(async () => {
    // Copy resource plant data of the default plant into resource data
    // because recipe differences will be matched only for the default plant resource properties
    let plantIndex=resource.resource_plants.findIndex(p => p.plant_id===Settings.default_plant_id)
    setResource(copyResourcePlant(resource, plantIndex))
    await onSave(resource);
  },[onSave, resource]);

  const handleClose = useCallback(() => {
    if (changed && resource !== initialResource) {
      confirmClose({
        open: true,
        onCancel: () => confirmClose({ open: false }),
        onConfirm: () => {
          setResource(initialResource)
          onClose();
          confirmClose({ open: false });
        }
      });
    } else {
      onClose();
    }
    resourceType=undefined
  }, [onClose, changed, resource]);

  return (
    <Dialog open={open} onClose={handleClose} style={{ display: 'flex', flexDirection: 'column' }} fullWidth={true}>
      <DialogTitle style={{marginBottom:'.75rem'}}>{resource.type ? <div style={{ display: 'flex', alignItems: 'center' }}><Avatar style={{ background: resourceTypes[resource.type].color, marginRight: 8 }}><img src={resourceTypes[resource.type].icon} alt="" style={{ width: 24, height: 24 }} /></Avatar><span>{resourceTypes[resource.type].title} toevoegen</span></div> : 'Kies een grondstof type'}</DialogTitle>
      <Divider />
      {!resourceType ? (
        <Fragment>
          <DialogContent style={{ background: grey[100] }}>
            <ChoseResourceType onClick={handleSelectResourceType} />
          </DialogContent>
          <Divider />
          <DialogActions>
            <span style={{ flex: 1 }} />
            <Button onClick={handleClose} color="secondary">Annuleren</Button>
          </DialogActions>
        </Fragment>
      ) : (
          <AddResourceSteps resources={resources} resource={resource} onChange={handleChanged} onPlantChange={handlePlantChanged} isValid={isValid} onSave={handleSave} onCancel={handleClose} />
        )}
      <ConfirmDialog {...dialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
    </Dialog>
  )
}

export default withRouter(AddResourceDialog);
