import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {ResourceType, ResourceUnion} from '../../types';
import {
  Badge,
  Button,
  DialogActions,
  Divider,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  StepContentProps
} from '@material-ui/core';
import ResourcePlantsStep from './ResourcePlantsStep';
import ExcipientPropertiesStep from './ExcipientPropertiesStep';
import SieveTestStep from './SieveTestStep';
import FillerPropertiesStep from './FillerPropertiesStep';
import {getAll as getPlants} from "../../HTTPClients/RecipeApp/plants";
import {Settings} from "../../config/settings";
import {Alert} from "@material-ui/lab";
import useAuthorized from "../../useAuthorized";
import NumericTextField from "../NumericTextField";
import CurrencyTextField from "../CurrencyTextField";
import {copyResourcePlant, emptyPlantResource, setResourcePlant} from "./serviceFunctions";

export type StepContentComponent<T extends ResourceUnion> = React.FC<{
  onChange: (resource: ResourceUnion) => void,
  onSave?: () => void,
  onCancel?: () => void,
  resources?: ResourceUnion[],
  plantId?: number,
  resource: T,
  active?: boolean,
} & Omit<StepContentProps, 'onChange' | 'resource' | 'children'>>

type AddResourceStepsProps = {
  resources: any[],
  resource: any,
  onChange: (resource: any) => void,
  onPlantChange?: (resource: any, plantIndex: number, newPlantIndex: number) => void,
  isValid?: boolean,
  onSave: () => Promise<void>,
  onCancel: () => void
}

const AddResourceSteps: React.FC<AddResourceStepsProps> = ({ resources, resource, onChange, onPlantChange, isValid, onSave, onCancel }) => {
  const [saving, setSaving] = useState(false)
  const disabled = !useAuthorized(['update:resources_info'])
  const priceHidden = !useAuthorized(['read:resources_price'])
  const [unique, setUnique]= useState(true);
  const [loaded, setLoaded] = useState(false)
  const [plants, setPlants] = useState([])
  const [plantId, setPlantId] = useState(0)
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(()=> {
    if(loaded) return;
    getPlants().then(
      function(response) {
        setLoaded(true);
        let plants=response.data.data;
        setPlants(plants);
      }
    )
  },[loaded, setPlants, setPlantId]);

  useEffect(()=> {
    if(!loaded) return;
    // First... preselect the default plant as set in settings
    setPlantId(Settings.default_plant_id)
  },[loaded, plants, setPlantId]);

  useEffect(()=>{
    firstInputRef.current && firstInputRef.current.focus()
  },[firstInputRef])

  // Plant related data tabset

  const tabPlantChange = (event: React.SyntheticEvent, newValue: number) => {
    let plantIndex=plants.findIndex(p => p.id===plantId)
    setPlantId(newValue)
    let newPlantIndex=plants.findIndex(p => p.id===newValue)

    onPlantChange(resource, plantIndex, newPlantIndex)
  }

  const onInputChange = useCallback((resource: any) => {
    onChange(resource)
  }, [onChange]);

  const onResourcePlantsChange = useCallback((resource: any) => {
    if(!resource.plants.length)
      // No visible plant resource tabs
      setPlantId(0)
    else {
      // Switch to another available plant tab if active plant is deselected
      let plantIndex=resource.plants.findIndex(p => p.id===plantId)
      if(plantIndex===-1) {
        // Can we switch to the default plant tab?
        if(resource.plants.findIndex(p => p.id===Settings.default_plant_id)>-1)
          setPlantId(Settings.default_plant_id) // Yes we can!
        else
          // Switch to the first available plant tab
          setPlantId(resource.plants[0].id)
      }

      // Check availability resource plant data for expanded plants selection
      for(let plant of resource.plants) {
        let plantIndex=resource.resource_plants.findIndex(p => p.plant_id===plantId)
        if(plantIndex===-1) {
          // This plant is selected but without related resource plant data
          resource.resource_plants.push(emptyPlantResource(plant.id))
          // Fill resource plant data with resource plant in first plant tab
          resource=copyResourcePlant(resource,0)
        }
      }

    }
    onChange(resource)
  },[onChange, plantId]);

  const checkIsUnique = useCallback((code: any) => {
    setUnique(isUnique(code))
  },[isUnique]);

  function isUnique(code: string) {
    if(!resources) return true;
    if(!resource.articleCode) return true;

    code=code.trim();
    if(!code) return true;

    for(let r of resources) {
      if(r.articleCode===null)
        continue;
      if(r.articleCode.toUpperCase()===code.toUpperCase() && r.id!==resource.id) {
        return false;
      }
    }

    return true;
  }

  const handleSave = useCallback(async () => {
    // Make sure that current (modified) tab data will be saved in resource
    let plantIndex=resource.resource_plants.findIndex(rp => rp.plant_id===plantId)
    // Copy resource data into related current resource plant data
    onChange(setResourcePlant(resource, plantIndex))

    setSaving(true)
    await onSave()
    setSaving(false)
  }, [resource, plantId, onChange, onSave])

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    e.stopPropagation() // Prevent event bubbling to scrollable parent
    setScrollTop(e.currentTarget.scrollTop);
  };

  // @ts-ignore
  return (
    <Fragment>
      <div className={'resource'} style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }} onScroll={handleScroll}>
        {Boolean(resource.name) && scrollTop>50 &&
          <div className={'title-content resource-name'}>
            <b>{resource.name}</b>
          </div>
        }
        {scrollTop>390 &&
          <Badge className={'title-content plant-name'} badgeContent={plants[plants.findIndex(p=>p.id===plantId)].name+' gegevens'} color="primary" overlap={'rectangular'}></Badge>}
        <div className={'dialog-section'}>
          <h4>Grondstof informatie</h4>
          <Grid container={true} spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Naam van de grondstof"
                value={resource.name || ''}
                onChange={e => onInputChange({ ...resource, name: e.target.value })}
                onBlur={e=> resource.articleCode=resource.articleCode ? resource.articleCode : e.target.value}
                variant="outlined"
                fullWidth={true}
                required={true}
                inputRef={firstInputRef}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                label="Artikel code"
                value={resource.articleCode || ''}
                onChange={e => { checkIsUnique(e.target.value); onInputChange({ ...resource, articleCode: e.target.value })}}
                variant="outlined"
                fullWidth={true}
                required={true}
                disabled={disabled}
              />
              {!unique && <Alert severity="warning">Geef svp een unieke artikel code op</Alert>}
            </Grid>
            {resource.type===ResourceType.Addition && !resource.id && <Grid item={true} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={resource.isSand || false}
                    onChange={e => onInputChange({ ...resource, isSand: e.target.checked })}
                    color="primary"
                    value="isSand"
                  />
                }
                label="Deze grondstof is een zand"
              />
            </Grid>}
          </Grid>
        </div>

        {resource.type===ResourceType.Excipient && <div className={'dialog-section next'}>
          <h4>Hulpstof eigenschappen</h4>
          <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} style={{paddingBottom: '0'}}>
                <ExcipientPropertiesStep resource={resource} resources={resources} onChange={onInputChange}></ExcipientPropertiesStep>
              </Grid>
          </Grid>
        </div>}

        <div className={'dialog-section next'}>
          <h4>Betoncentrale(s)</h4>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} style={{paddingBottom: '0'}}>
              <ResourcePlantsStep resource={resource} resources={resources} onChange={onResourcePlantsChange}></ResourcePlantsStep>
            </Grid>
          </Grid>
        </div>

        {resource.plants.length>0 && plantId>0 &&
          <div className={'dialog-section'}>
            <h4>Grondstof gegevens per betoncentrale</h4>
            <Divider></Divider>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Tabs value={plantId} onChange={tabPlantChange} variant="scrollable" scrollButtons="auto">
                  {resource.plants.map(plant => (
                    <Tab label={plant.name+'/'+plants.findIndex(p => p.id===plant.id)} value={plant.id} key={'tab-'+plant.id} />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
          </div>}

          <div className={'dialog-section next cascade'}>
            <h4>Grondstof informatie</h4>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <TextField
                  label="Leverancier"
                  value={resource.supplier || ''}
                  onChange={e => onInputChange({ ...resource, supplier: e.target.value })}
                  variant="outlined"
                  fullWidth={true}
                  disabled={disabled}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  label="Merknaam"
                  value={resource.brand || ''}
                  onChange={e => onInputChange({ ...resource, brand: e.target.value })}
                  variant="outlined"
                  fullWidth={true}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </div>

          {!priceHidden && <div className={'dialog-section next cascade'}>
            <h4>Grondstof prijs</h4>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={4}>
                <CurrencyTextField
                  label="Basisprijs (p/t)"
                  value={resource.price || null }
                  onChange={(e: any) => onInputChange({ ...resource, price: e.target.value })}
                  fullWidth={true}
                  required={true}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </div>}

          <div className={'dialog-section next cascade'}>
            <h4>Grondstof eigenschappen</h4>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Volumieke massa"
                  value={resource.density}
                  onChange={(e: any) => onInputChange({ ...resource, density: e.target.value })}
                  fullWidth={true}
                  required={true}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg/m<sup>3</sup></InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Chloridegehalte"
                  value={typeof resource.chloridePercentage !== 'undefined' && resource.chloridePercentage !== null ? resource.chloridePercentage : ''}
                  onChange={(e: any) => onInputChange({ ...resource, chloridePercentage: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={6}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Alkali gehalte"
                  value={typeof resource.alkaliPercentage !== 'undefined' && resource.alkaliPercentage !== null ? resource.alkaliPercentage : ''}
                  onChange={(e: any) => onInputChange({ ...resource, alkaliPercentage: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={6}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          {resource.type===ResourceType.Cement && <div className={'dialog-section next cascade'}>
            <h4>Cement eigenschappen</h4>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="C-waarde"
                  value={typeof resource.cvalue !== 'undefined' && resource.cvalue !== null ? resource.cvalue : ''}
                  onChange={(e: any) => onInputChange({ ...resource, cvalue: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={2}
                  required={false}
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Sterkte 7 dagen"
                  value={typeof resource.strengthWeek !== 'undefined' && resource.strengthWeek !== null ? resource.strengthWeek : ''}
                  onChange={(e: any) => onInputChange({ ...resource, strengthWeek: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={6}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
                  }}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Sterkte 28 dagen"
                  helperText="(Normwaarde)"
                  value={typeof resource.strengthNorm !== 'undefined' && resource.strengthNorm !== null ? resource.strengthNorm : ''}
                  onChange={(e: any) => onInputChange({ ...resource, strengthNorm: e.target.value })}
                  fullWidth={true}
                  required={true}
                  maximumFractionDigits={6}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">N/mm<sup>2</sup></InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
          </div>}
          {resource.type===ResourceType.Filler && <div className={'dialog-section next cascade'}>
            <h4>Vulstof eigenschappen</h4>
            <FillerPropertiesStep resource={resource} onChange={onInputChange}></FillerPropertiesStep>
          </div>}
          <div className={'dialog-section next cascade'}>
            {resource.type===ResourceType.Excipient && <h4>Hulpstof eigenschappen</h4>}
            {resource.type===ResourceType.Addition && <h4>Toeslag eigenschappen</h4>}
            {(resource.type===ResourceType.Excipient || resource.type===ResourceType.Addition) &&
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <NumericTextField
                    label="Vochtgehalte (incl. absorptie)"
                    value={typeof resource.moisture !== 'undefined' && resource.moisture !== null ? resource.moisture : ''}
                    onChange={(e: any) => onInputChange({ ...resource, moisture: e.target.value })}
                    fullWidth={true}
                    maximumFractionDigits={1}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NumericTextField
                    label="Absorptie"
                    value={typeof resource.absorption !== 'undefined' && resource.absorption !== null ? resource.absorption : ''}
                    onChange={(e: any) => onInputChange({ ...resource, absorption: e.target.value })}
                    fullWidth={true}
                    maximumFractionDigits={1}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>}
          </div>
          {resource.type===ResourceType.Addition && <div className={'dialog-section next cascade'}>
            <h4>Zeefanalyse</h4>
            <SieveTestStep plantId={plantId} resource={resource} resources={resources} onChange={onInputChange}></SieveTestStep>
          </div>}
          {resource.type===ResourceType.Extra && <div className={'dialog-section next cascade'}>
            <h4>Extra eigenschappen</h4>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Percentage fijn"
                  value={typeof resource.percentageFine !== 'undefined' && resource.percentageFine !== null ? resource.percentageFine : ''}
                  onChange={(e: any) => onInputChange({ ...resource, percentageFine: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={3}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Vochtgehalte (Incl. absorptie)"
                  value={typeof resource.moisture !== 'undefined' && resource.moisture !== null ? resource.moisture : ''}
                  onChange={(e: any) => onInputChange({ ...resource, moisture: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={1}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <NumericTextField
                  label="Absorptie"
                  value={typeof resource.absorption !== 'undefined' && resource.absorption !== null ? resource.absorption : ''}
                  onChange={(e: any) => onInputChange({ ...resource, absorption: e.target.value })}
                  fullWidth={true}
                  maximumFractionDigits={1}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
          </div>}
        <Divider />
        <DialogActions style={{ position: 'relative' }}>
          <span style={{ flex: 1 }} />
          <Button onClick={() => onCancel()} color="secondary">Annuleren</Button>
          <Button id={'save_resource'} onClick={handleSave} disabled={!isValid || !isUnique || saving} color="primary">Opslaan</Button>
          {saving && <LinearProgress variant="indeterminate" style={{ position: 'absolute', top: 0, left: -8, width: '100%', marginRight: -8 }} />}
        </DialogActions>
      </div>
    </Fragment>
  )
}

export default AddResourceSteps;
