import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { Recipe, RecipeRevision, ResourceUnion } from '../../../types'
import { useParams, useHistory } from 'react-router-dom'
import { BreadcrumbsContext } from '../../../views/PageWithBreadcrumbs'
import { Box, Slider, makeStyles, Tooltip, Divider, Typography, Button, Icon, Table, TableHead, TableRow, TableCell, TableBody, IconButton, useTheme, FormControlLabel, Checkbox, Link, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core'
import moment from 'moment'
import { grey } from '@material-ui/core/colors'
import { getComputations } from '../../../computed/recipeComputations'
import { diff } from 'deep-object-diff'
import ComputedTableRows from './ComputedTableRows'
import ConfirmDialog, { ConfirmDialogProps } from '../../ConfirmDialog'
import {getResourceRevisions} from "../../../HTTPClients/RecipeApp/recipes/recipe-revisions";
import ResourceRevisionsTable from "./RevisionAmountsTable";
import {getAll as getResources} from "../../../HTTPClients/RecipeApp/resources/resources";
import {getById} from "../../../HTTPClients/RecipeApp/recipes/recipes";

const alphabet = ['A', 'B', 'C']

const ThumbComponent: React.FC<any> = props => {
  const theme = useTheme()
  return <span {...props} style={{ ...props.style, top: 57, marginLeft: -12, width: 24, height: 24, background: theme.palette.primary.main, color: theme.palette.common.white }}>{alphabet[(props as any)['data-index'] as number]}</span>
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 50
  },
  rail: {
    height: 50,
    background: grey[50],
    border: '1px solid ' + theme.palette.divider
  },
  mark: {
    background: theme.palette.secondary.main,
    height: 52,
    width: 2,
    marginLeft: -1
  },
  thumb: {
    top: 63
  },
  table: {
    '& td, th': {
      borderLeft: '1px solid ' + theme.palette.divider
    },
    '& td:first-child, th:first-child': {
      borderLeft: 0
    }
  },
  letterCircle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 12,
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}))

const Revisions: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  const params = useParams()
  const current = useMemo(() => getComputations(recipe), [recipe])
  const { createBreadcrumbs, setToolbarButton } = useContext(BreadcrumbsContext)
  const { table, letterCircle, ...sliderClasses } = useStyles()
  const [values, setValues] = useState([] as number[])
  // Ingredients per recipe revision [ <revision_id>, [ <ingredient> ] ]
  const [resourceRevisions, setResourceRevisions] = useState([])
  const [onlyDiff, setOnlyDiff] = useState(false)
  const [dialogProps, confirmReset] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)
  const [resources, setResources] = useState([]);

  if(!resources.length) {
    getResources(true).then(
        function(response) {
          setResources(response.data.data);

          const { recipeId, ...revisions } = params as any
          const revisionIds = Object.keys(revisions).map(k => revisions[k]).filter(id=>id!==undefined);
          revisionIds.push(recipe.revision.toString());
          getResourceRevisions(revisionIds).then(
              function(response) {
                setResourceRevisions(response.data.data);
              }
          )
        }
    )
  }

  const { min, max } = useMemo(() => getMinMaxDates(recipe.revisions), [recipe.revisions])
  const marks = useMemo(() => recipe.revisions.map(({ date, id }) => ({ value: moment(date).toDate().getTime(), id })), [recipe.revisions])
  const history = useHistory()
  const [revisions, setRevisions] = useState([] as Array<RecipeRevision & { computations: typeof current, diff: Partial<typeof current>, showReset: boolean }>)
  const compare = useMemo(() => {
    const { recipeId, ...revisions } = params as any
    const ids = Object.keys(revisions).map(k => revisions[k])
    return marks.filter(m => ids.indexOf(m.id + '') >= 0)
  }, [params, marks])

  useEffect(() => setToolbarButton(null), [setToolbarButton])

  useEffect(() => {
    if (resources.length && resources) {
      const promisses = compare.map(async m => {
        let rev = recipe.revisions.find(r => r.id === m.id) as RecipeRevision

        // Get recipe revision data
        let result=await getById(rev.id)
        rev.recipe=result.data.data

        const computations = getComputations(rev.recipe)
        const resourceIds = rev.recipe.ingredients.map(r => r.resource.id)
        const showReset = resourceIds.filter(id => resources.map((r: ResourceUnion) => r.id).indexOf(id) >= 0).length === resourceIds.length
        return { ...m, ...rev, computations, diff: diff(current, computations), showReset }
      })
      Promise.all(promisses).then(revs => setRevisions(revs.sort((a, b) => a.value - b.value)))
    }
  }, [compare, recipe, recipe.revisions, current, resources])

  useEffect(() => {
    if (min && max) {
      setValues([min, max])
    }
  }, [min, max])

  useEffect(() => {
    createBreadcrumbs([
      { title: 'Recepten', link: '/recipe' },
      { title: 'Recept aanpassen', link: `/recipe/${recipe.id}` },
      { title: 'Recept overzicht', link: `/recipe/${recipe.id}/overview` },
      { title: `Revisies overzicht recept ${recipe.recipeName}`, link: `/recipe/${recipe.id}/revision/${compare.map(c => c.id).join('/')}` }
    ])
  }, [createBreadcrumbs, recipe, compare])

  const handleAdd = useCallback(() => {
    const next = [...marks].sort((a, b) => a.value - b.value).filter(m => compare.map(c => c.id).indexOf(m.id) < 0).pop()
    if (next && compare.length < 3) {
      compare.push(next)
      history.push(`/recipe/${recipe.id}/revision/${compare.map(c => c.id).join('/')}`)
    }
  }, [compare, history, marks, recipe.id])

  const handleChange = useCallback((e: any, v: number[] | number) => {
    if (Array.isArray(v)) {
      const matches = marks.filter(m => v.indexOf(m.value) >= 0)
      matches && history.push(`/recipe/${recipe.id}/revision/${matches.map(m => m.id).join('/')}`)
    }
  }, [history, marks, recipe])

  const handleRemove = useCallback((id: number) => {
    history.push(`/recipe/${recipe.id}/revision/${compare.filter(c => c.id !== id).map(c => c.id).join('/')}`)
  }, [history, compare, recipe.id])

  const handleReset = useCallback((id: number) => {
    confirmReset({
      open: true,
      onCancel: () => confirmReset({ open: false }),
      onConfirm: () => {
        confirmReset({ open: false })
        history.push(`/recipe/${recipe.id}/overview`, { reset: id, previous: history.location.pathname })
      }
    })
  }, [history, recipe.id])

  /* @ts-ignore */
  // @ts-ignore
  return <Box>
    {/* @ts-ignore */}
    <Box display="flex">
      {/* @ts-ignore */}
      <Box padding={2} paddingBottom={1} paddingTop={4} flex={1} overflow="hidden">
        <Typography variant="caption">Datum bereik selecteren</Typography>
        <Slider
          value={values}
          valueLabelDisplay="on"
          ValueLabelComponent={({ children, value }) => <Tooltip open={true} enterTouchDelay={0} placement="top" title={value} PopperProps={{ style: { zIndex: 1000 } }}>{children}</Tooltip>}
          valueLabelFormat={t => <Typography variant="body2">{moment(t).format('D MMM YYYY H:mm')}</Typography>}
          step={1}
          min={min}
          max={max}
          onChange={(e, v) => setValues(v as number[])}
        />
        <Typography variant="caption">Revisie punt(en) kiezen</Typography>
        <Slider
          value={compare.map(c => c.value)}
          onChange={handleChange}
          step={null}
          min={values[0]}
          max={values[1]}
          marks={marks.filter(r => r.value >= values[0] && r.value <= values[1])}
          track={false}
          classes={sliderClasses}
          ThumbComponent={ThumbComponent}
        />
      </Box>
    </Box>
    {/* @ts-ignore */}
    <Box display="flex" paddingLeft={2} paddingRight={2} paddingBottom={1}>
      <FormControlLabel
        control={<Checkbox checked={onlyDiff} onChange={() => setOnlyDiff(!onlyDiff)} />}
        label="Toon alleen verschillen"
      />
      <span style={{ flex: 1 }} />
      {compare.length < 3 && <Button size="small" color="secondary" onClick={handleAdd}><Icon>add_circle</Icon>&nbsp;&nbsp;Nog een revisie toevoegen aan vergelijking</Button>}
    </Box>
    <Divider />
    <Table className={table}>
      <TableHead>
        <TableRow>
          <TableCell />
          {revisions.map(({ id, date, showReset }, k) => <TableCell key={k} style={{ position: 'relative' }}>
            {/* @ts-ignore */}
            <Box display="flex" alignItems="center">
              <span className={letterCircle}>{alphabet[k]}</span>
              {/* @ts-ignore */}
              <Box display="flex" flexDirection="column">
                <span style={{ flex: 1 }}>{moment(date).fromNow()}, {moment(date).format('D MMM YYYY H:mm')}</span>
                {showReset && <Link style={{ cursor: 'pointer' }} onClick={() => handleReset(id)}><Typography variant="body2">Revisie terugzetten</Typography></Link>}
              </Box>
            </Box>
            {revisions.length > 1 && <IconButton size="small" onClick={() => handleRemove(id)} style={{ position: 'absolute', top: 8, right: 8 }}><Icon>clear</Icon></IconButton>}
          </TableCell>)}
          <TableCell>Huidig recept</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Aangepast door</TableCell>
          {revisions && revisions.map(({ user }, k) => <TableCell>
            {user && <List disablePadding={true}>
              <ListItem dense={true} style={{ padding: 0 }}>
                <ListItemAvatar style={{ minWidth: 36 }}><Avatar src={user.picture} alt={user.name} style={{ width: 20, height: 20 }} /></ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItem>
            </List>}
          </TableCell>)}
          <TableCell>
            {recipe.revisions.length>0 && recipe.revisions[0].user && <List disablePadding={true}>
              <ListItem dense={true} style={{ padding: 0 }}>
                <ListItemAvatar style={{ minWidth: 36 }}><Avatar src={recipe.revisions[0].user.picture} alt={recipe.revisions[0].user.name} style={{ width: 20, height: 20 }} /></ListItemAvatar>
                <ListItemText primary={recipe.revisions[0].user.name} />
              </ListItem>
            </List>}
          </TableCell>
        </TableRow>
        <ComputedTableRows current={current} revisions={revisions} onlyDiff={onlyDiff} />
        <ResourceRevisionsTable resourceRevisions={resourceRevisions} onlyDiff={onlyDiff} />
      </TableBody>
    </Table>
    <ConfirmDialog title="Revisie terugzetten" content="De gegevens van de grondstoffen die aan dit recept gekoppeld zijn kunnen in de tussentijd veranderd zijn. Controleer daarom goed de berekende waardes in het volgende scherm!" confirmText="Volgende" {...dialogProps} />
  </Box>
}

export default Revisions

function getMinMaxDates(revisions: RecipeRevision[]) {
  const sorted = [...revisions].sort((a, b) => toDate(a.date).getTime() - toDate(b.date).getTime())
  const earliest = sorted.shift()
  if (earliest) {
    return {
      min: moment(earliest.date).toDate().getTime(),
      max: moment().toDate().getTime()
    }
  } else {
    return {
      min: 0, max: 0
    }
  }
}

function toDate(date: Date | string) {
  return moment(date).toDate()
}
