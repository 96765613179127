import React, {useCallback, useState, ReactText, Fragment, useEffect} from 'react';
import { Grid, VirtualTable, Table, TableHeaderRow, TableSelection, Toolbar, SearchPanel, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import {
  Column,
  IntegratedSelection,
  SelectionState,
  IntegratedFiltering,
  SearchState,
  FilteringState,
  SortingState,
  IntegratedSorting,
  Sorting,
  Filter
} from '@devexpress/dx-react-grid';
import { TextField, InputAdornment, Icon, Toolbar as MuiToolbar, Divider, useTheme, Typography, makeStyles, Theme, TableRow } from '@material-ui/core';

const GridComponent: React.FC<Grid.RootProps> =
    props =>
    // @ts-ignore
    <Grid.Root {...props} style={{ flex: 1, overflow: 'auto', height: '100%' }} />

const CellComponent: React.FC<Table.DataCellProps & { column: Column & { renderCellComponent?: React.FC<{ row: any }> } }> =
    ({ tableColumn, column, ...cellProps }) => column.renderCellComponent ?
        <column.renderCellComponent row={cellProps.row} /> :
        // @ts-ignore
        <VirtualTable.Cell {...cellProps} column={column} tableColumn={tableColumn} {...tableColumn.width === 64 && { style: { padding: 0 } }} />

const SearchInputComponent: React.FC<SearchPanel.InputProps> =
    ({ onValueChange, getMessage, ...props }) =>
    <TextField {...props} onChange={e => onValueChange(e.target.value)} variant="outlined" margin="dense" placeholder="Zoeken..."
    InputProps={{ startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment> }} style={{ marginLeft: 8, width: '10rem' }} />

const FilterEditorComponent: React.FC<TableFilterRow.EditorProps> =
    ({ onChange, getMessage, value, disabled, ...props }) =>
    !disabled ? <TextField {...props} onChange={e => onChange(e.target.value)} value={value || ''} variant="outlined"
       margin="dense" placeholder="Filter..." fullWidth={true} style={{ marginLeft: 8, marginRight: 8 }} /> : null

const FilterCellComponent: React.FC<TableFilterRow.CellProps & { column: Column & { filterCellComponent?: React.FC<TableFilterRow.CellProps> } }> =
    ({ column, ...filterCellProps }) =>
    column.filterCellComponent ?
        // @ts-ignore
        <TableFilterRow.Cell column={column} {...filterCellProps}><column.filterCellComponent column={column} {...filterCellProps} />
        </TableFilterRow.Cell> :
        // @ts-ignore
        <TableFilterRow.Cell column={column} {...filterCellProps} />;

const useRowStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .row-hover-buttons': {
      display: 'none'
    },
    '&:hover': {
      background: theme.palette.grey[100]
    },
    '&:hover .row-hover-buttons': {
      display: 'flex'
    }
  }
}))

const RowComponent: React.FC<Table.DataRowProps> = ({ children }) => {
  const classes = useRowStyles();
  return <TableRow classes={classes}>{children}</TableRow>
};

type Action = {
  columnName: string,
  render: React.FC<any>
}

type DataTableProps = {
  title?: string,
  rows: Array<any>,
  columns: Array<Column & { width?: number, filteringEnabled?: boolean, renderCellComponent?: React.FC<{ row: any }>,
    filterCellComponent?: React.FC<TableFilterRow.CellProps> }>,
  defaultFilters?: Filter[];
  onFiltersChange?: (filters: Filter[]) => void;
  actions: Action[],
  withSelection?: boolean,
  withSearch?: boolean,
  withFiltering?: boolean,
  withSorting?: boolean,
  sorting?: Sorting[],
  onSortingChange?: (sorting: Sorting[]) => void;
  onSelectionChange?: Array<number | string | ReactText>;
  AddNewComponent?: React.FC,
  ToolbarSelectionComponent?: React.FC<{ selection: Array<string | number | ReactText> }>
}

const DataTable: React.FC<DataTableProps> =
    ({ title, AddNewComponent, ToolbarSelectionComponent, withSelection, defaultFilters,
       onFiltersChange, onSortingChange, onSelectionChange,
       withSearch, withFiltering, withSorting, sorting, actions,
       columns, ...gridProps }) =>
    {
  const [selection, setSelection] = useState([] as Array<string | number | ReactText>);
  const theme = useTheme();

  const actionColumns: Column[] = actions.map(({ columnName, render }) => ({
    name: columnName,
    title: ' ',
    getCellValue: render
  }));

  useEffect(() => {
    setSelection([]);
  }, [gridProps.rows]);

  const renderToolbar = useCallback(() => {
    return ({ children }: any) => (
      <Fragment>
        <MuiToolbar {...selection.length > 0 && { style: { background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText } }}>
          {title && <Typography variant="h6">{title}</Typography>}
          <span style={{ flex: 1 }} />
          {selection.length === 0 && AddNewComponent && <AddNewComponent />}
          {selection.length === 0 ? children : ToolbarSelectionComponent ? <ToolbarSelectionComponent selection={selection} /> : null}
        </MuiToolbar>
        <Divider />
      </Fragment>
    );
  }, [selection, theme, title, AddNewComponent, ToolbarSelectionComponent]);

  const defaultColumnWidths = [...actions.map(({ columnName }) =>
      ({ columnName, width: 64 })), ...columns.filter(({ width }) => Boolean(width)).map(({ name, width }) =>
      ({ columnName: name, width: width || 'auto' }))]

  const selectionChange = useCallback((selection: Array<number | string | ReactText>) => {
    setSelection(selection)
    // @ts-ignore
    onSelectionChange(selection)
  },[setSelection]);

  let children;
  return (
    // @ts-ignore
    <Grid {...children} {...gridProps} getRowId={row => row.id} columns={[...columns, ...actionColumns]} rootComponent={GridComponent}>
      {/* @ts-ignore */}
      {withSearch && <SearchState />}

      {/* @ts-ignore */}
      {withFiltering && <FilteringState defaultFilters={defaultFilters} onFiltersChange={onFiltersChange}
          columnExtensions={[...actions.map(({ columnName }) =>
          ({ columnName, filteringEnabled: false })), ...columns.filter(({ filteringEnabled }) =>
          typeof filteringEnabled !== 'undefined').map(({ name, filteringEnabled }) =>
          ({ columnName: name, filteringEnabled: filteringEnabled as boolean }))]} />}
      {/* @ts-ignore */}
      {withSorting && <SortingState sorting={sorting} onSortingChange={onSortingChange} />}
      {/* @ts-ignore */}
      {withSelection && <SelectionState selection={selection} onSelectionChange={selectionChange} />}
      {/* @ts-ignore */}
      {(withSearch || withFiltering) && <IntegratedFiltering />}
      {/* @ts-ignore */}
      {withSorting && <IntegratedSorting />}
      {/* @ts-ignore */}
      {withSelection && <IntegratedSelection />}
      {/* @ts-ignore */}
      <VirtualTable
        columnExtensions={defaultColumnWidths}
        cellComponent={CellComponent}
        rowComponent={RowComponent}
        messages={{ noData: 'Geen data' }}
        height="100%"
      />
      {/* @ts-ignore */}
      <TableHeaderRow showSortingControls={withSorting} />
      {/* @ts-ignore */}
      <Toolbar rootComponent={renderToolbar()} />
      {/* @ts-ignore */}
      {withSearch && <SearchPanel inputComponent={SearchInputComponent} />}
      {/* @ts-ignore */}
      {withFiltering && <TableFilterRow editorComponent={FilterEditorComponent} cellComponent={FilterCellComponent} />}
      {/* @ts-ignore */}
      {withSelection && <TableSelection showSelectAll />}
    </Grid>
  )
}

export default DataTable;
