import React from 'react'
import { format } from 'react-numberinput-formatter';
import {TableRow, TableCell, useTheme, Typography} from '@material-ui/core';

type ResourceRevisionsProps = {
  resourceRevisions: any[],
  onlyDiff?: boolean
}

const ResourceRevisionsTable: React.FC<ResourceRevisionsProps> = ({ resourceRevisions, onlyDiff}) => {
  if(onlyDiff) {
    let rr=[];
    for(let resourceRevision of resourceRevisions)
      for(let ra=0; ra<resourceRevision.revisionAmounts.length-1; ra++)
        if(resourceRevision.revisionAmounts[ra].amount!==resourceRevision.revisionAmounts[ra+1].amount) {
          rr.push(resourceRevision);
          break;
        }
    resourceRevisions=rr;
  }

  return <React.Fragment>
    {resourceRevisions.map( (resource) => (
        <TableRow key={'rr-'+resource.id}>
          <TableCell>{resource.name}</TableCell>
          <RevisionAmounts revisionAmounts={resource.revisionAmounts}></RevisionAmounts>
        </TableRow>
    ))}
  </React.Fragment>
}

type RevisionAmountsProps = {
  revisionAmounts: any[]
}

const RevisionAmounts: React.FC<RevisionAmountsProps> = ({ revisionAmounts}) => {
  const theme = useTheme()
  let current=revisionAmounts[revisionAmounts.length-1]



  return <React.Fragment>
    {revisionAmounts.map( (revisionAmount) => {
      if(revisionAmount.revision_id===current.revision_id)
        return revisionAmount.amount>0
          ? <TableCell key={'ra-'+revisionAmount.revision_id}>{format(revisionAmount.amount,{maximumFractionDigits: 2})} kg</TableCell>
          : <TableCell key={'ra-'+revisionAmount.revision_id}>N.v.t.</TableCell>
      else {
        let diff=revisionAmount.amount - current.amount

        let backgroundColor='#fff'
        if(diff>0) backgroundColor='#c0e3c2'
        if(diff<0) backgroundColor='#f2b9b9'

        return revisionAmount.amount>0
          ? <TableCell key={'ra-'+revisionAmount.revision_id} style={{backgroundColor: backgroundColor}}>{format(revisionAmount.amount,{maximumFractionDigits: 2})} kg
              {diff!==0 && <Typography variant="caption" style={{ color: diff > 0 ? theme.palette.success.dark : theme.palette.error.dark }}> ({`${diff > 0 ? '+' : ''}${format(diff,{maximumFractionDigits: 2})}`})</Typography>}
            </TableCell>
          : <TableCell key={'ra-'+revisionAmount.revision_id} style={{backgroundColor: backgroundColor}}>N.v.t.
            {diff!==0 && <Typography variant="caption" style={{ color: diff > 0 ? theme.palette.success.dark : theme.palette.error.dark }}> ({`${diff > 0 ? '+' : ''}${format(diff,{maximumFractionDigits: 2})}`})</Typography>}
          </TableCell>
      }
    })}
  </React.Fragment>
}
export default ResourceRevisionsTable
