export const Settings = {
  environment: 'development', // 'development' or 'production'
  company: 'server_centraal',
default_plant_id: 3,
  URLs: {
    client: '',
    server: 'https://mcg.backend.jbmsoftware.nl',
    planningApp: {
      recipeApp: {
        root: 'https://mcg.backend.jbmsoftware.nl/recipe-app/',
        loginSegment: 'post/login'
      }
    },
    labApp: 'https://mcg.lab.jbmsoftware.nl',
    orderApp: 'https://mcg.jbmsoftware.nl',
appLogin:'https://mcg.backend.jbmsoftware.nl/recipe-app/post/login'
  }
}

