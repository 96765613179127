import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { StepContentComponent } from './AddResourceSteps';
import {ResourceUnion} from '../../types';
import { FormControl, InputLabel, Select, Chip, MenuItem, Checkbox, ListItemText, makeStyles, Theme } from '@material-ui/core';
import {getAll} from '../../HTTPClients/RecipeApp/plants';
import {Settings} from "../../config/settings";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
      horizontal: "left"
  },
  PaperProps: {
    style: { maxHeight: 342}
  },
  getContentAnchorEl: null,
  variant: 'menu'
};

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8.5px 0'
  },
  chip: {
    margin: 2,
  }
}));

const ResourcePlantsStep: StepContentComponent<ResourceUnion> = ({ onChange, resource}) => {
  const [plants, setPlants] = useState([] as any[])
  const classes = useStyles()
  const defaultPlantId = Settings.default_plant_id

  useEffect(() => {
    if(plants.length) return
    getAll().then(
        function(response) {
          setPlants(response.data.data)
        }
    )
  },[plants, setPlants])

  const handleChange = useCallback((e: ChangeEvent<{ value: unknown }>) => {
    let selection=plants.filter(p => p.id && (e.target.value as unknown as number[]).indexOf(p.id) >= 0)
    onChange({ ...resource, plants: selection });
  }, [onChange, plants, resource]);

  return (
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel htmlFor="select-multipe-plants" required={true}>Selecteer centrale(s)</InputLabel>
        <Select
          labelWidth={0}
          label={'Selecteer centrale(s)'}
          multiple={true}
          value={resource.plants.map(p => p.id)}
          onChange={handleChange}
          inputProps={{id: 'select-multipe-plants',}}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as number[]).map(id => {
                const plant = plants.find(p => p.id === id);
                return (
                  <Chip key={id} label={plant && plant.name} className={classes.chip} />
                )
              })}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {plants.map(plant => (
            <MenuItem key={plant.id} value={plant.id} disabled={defaultPlantId===plant.id}>
              <Checkbox checked={resource.plants.map(p => p.id).indexOf(plant.id) > -1} disabled={defaultPlantId===plant.id} />
              <ListItemText primary={plant.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}

export default ResourcePlantsStep;
